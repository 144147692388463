<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
        <div class="page-header">
            <h1>Liste de tous les <span style="color:#ffa409">devis cloud</span> </h1>
        </div>

        <div class="toolbar-box flex align-center" v-if="!isMobile">
            <div class="box grow">
                <el-input
                        placeholder="Rechercher devis..."
                        prefix-icon="el-icon-search"
                        v-model="search"
                        clearable
                >
                </el-input>
            </div>
            <div class="box grow">
                <!--        A ne pas supprimer-->
            </div>
        </div>

        <div v-if="isMobile" style="margin-bottom:15px">
            <el-row :gutter="15">
                <el-col :span="24" style="margin-top:15px">
                    <el-input
                            placeholder="Rechercher devis..."
                            prefix-icon="el-icon-search"
                            v-model="search"
                            clearable>
                    </el-input>
                </el-col>
            </el-row>
        </div>

        <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="!isMobile">
            <div class="box grow">
                <h3>Recherche par période de création</h3>
                <el-date-picker
                        v-model="du"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-date-picker
                        style="margin-left:15px"
                        v-model="au"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
            </div>
        </div>

        <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="isMobile">
            <div class="box grow">
                <h3>Recherche par période de création</h3>
                <el-date-picker
                        v-model="du"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-date-picker
                        style="margin-top:15px"
                        v-model="au"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
            </div>
        </div>

        <div class="mb-5" v-if="this.$store.state.user.role == 'SuperAdmin'||this.$store.state.user.role == 'Admin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
            <el-table
                    :data="listInPage"
                    style="width: 100%"
                    :height="height"
                    v-if="ready">
                <el-table-column label="Date création" fixed min-width="130" prop="dateSouscription">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="200" prop="partner">
                  <template slot-scope ="scope">
                        <span class="date">
                        <a href="#">
                            {{getPartner(scope.row)}}
                        </a>
                        </span>
                  </template>
              </el-table-column>
                <el-table-column label="Client" min-width="150" prop="client">
                    <template slot-scope ="scope">
                <span class="date">
                  <a href="#" @click="SetDetailUser(scope.row.userid)">
                    {{scope.row.userid.prenom }} - {{scope.row.userid.nom }}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Num devis" min-width="180" prop="numeroDevis"></el-table-column>
                <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
                <el-table-column label="Code Partenaire" min-width="150" prop="codep">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Plateforme" min-width="150" prop="plateforme">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.plateforme }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
                    <template slot-scope ="scope">
                        <span class="date">{{scope.row.prime_ttc.toLocaleString()}} CFA</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Créateur" min-width="180" prop="creator">
                    <template slot-scope ="scope" v-if="$store.state.user.role=='Admin' ||  $store.state.user.role=='Agent'">
                        <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
                        <span v-else>
              Admin
            </span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
                        <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading" @click="Downloa_devis(scope.row)" type="primary" icon="el-icon-download" circle></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    v-if="ready"
                    :small="pagination.small"
                    :current-page.sync="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size.sync="pagination.size"
                    :layout="pagination.layout"
                    :total="total"
            ></el-pagination>
        </div>

        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
            <el-table
                    :data="listInPage"
                    style="width: 100%"
                    v-if="ready">
                <el-table-column label="Date création" min-width="150" prop="dateSouscription">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Client" min-width="200" prop="client">
                    <template slot-scope ="scope">
                <span class="date">
                  <a href="#" @click="SetDetailUser(scope.row.userid)">
                    {{scope.row.userid.prenom }} - {{scope.row.userid.nom }}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Num devis" min-width="180" prop="numeroDevis"></el-table-column>
                <el-table-column label="Branche" min-width="150" prop="brancheid.branche"></el-table-column>
                <el-table-column label="Code Partenaire" min-width="150" prop="codep">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Plateforme" min-width="150" prop="plateforme">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.plateforme }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
                    <template slot-scope ="scope">
                        <span>{{scope.row.prime_ttc.toLocaleString()}} CFA</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Créateur" min-width="180" prop="creator">
                    <template slot-scope ="scope">
                        <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
                        <span v-else>
              Admin
            </span>
                    </template>
                </el-table-column>
                <el-table-column label="Actions" prop="action" min-width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
                        <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading" @click="Downloa_devis(scope.row)" type="primary" icon="el-icon-download" circle></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    v-if="ready"
                    :small="pagination.small"
                    :current-page.sync="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size.sync="pagination.size"
                    :layout="pagination.layout"
                    :total="total"
            ></el-pagination>
        </div>

        <el-dialog width="70%!important" title="Détails Devis" :visible.sync="showdevis" v-if="showdevis==true && !isMobile">
            <el-row :gutter="15">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateSouscription)}}</span></li>
                                    <li>Numéro devis : <span class="to-right assuraf-orange">{{detailsDevis.numeroDevis}}</span></li>
                                    <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEffet)}}</span></li>
                                    <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEcheance)}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Prime nette : <span class="to-right assuraf-orange">{{detailsDevis.prime_nette.toLocaleString()}} CFA</span></li>
                                    <li>Prime ttc : <span class="to-right assuraf-orange">{{detailsDevis.prime_ttc.toLocaleString()}} CFA</span></li>
                                    <li>Taxe : <span class="to-right assuraf-orange">{{detailsDevis.taxe.toLocaleString()}} CFA</span></li>
                                    <li>Accessoires : <span class="to-right assuraf-orange">{{detailsDevis.accessoires.toLocaleString()}} CFA</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="90%!important" title="Détails Devis" :visible.sync="showdevis" v-if="showdevis==true && isMobile">
            <el-row :gutter="15">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateSouscription)}}</span></li>
                                    <li>Numéro devis : <span class="to-right assuraf-orange">{{detailsDevis.numeroDevis}}</span></li>
                                    <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEffet)}}</span></li>
                                    <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEcheance)}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Prime nette : <span class="to-right assuraf-orange">{{detailsDevis.prime_nette.toLocaleString()}} CFA</span></li>
                                    <li>Prime ttc : <span class="to-right assuraf-orange">{{detailsDevis.prime_ttc.toLocaleString()}} CFA</span></li>
                                    <li>Taxe : <span class="to-right assuraf-orange">{{detailsDevis.taxe.toLocaleString()}} CFA</span></li>
                                    <li>Accessoires : <span class="to-right assuraf-orange">{{detailsDevis.accessoires.toLocaleString()}} CFA</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="70%" title="Client" :visible.sync="showUser" v-if="showUser==true && !isMobile">
            <el-row :gutter="15">
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser==true && isMobile">
            <el-row :gutter="15">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>


    import _ from "lodash"
    import moment from "moment-timezone"

    //debut importation assuraf
    import dayjs from 'dayjs'
    import Api from '../../../services/Api'
    import store from '../../../store'
    //fin importation assuraf

    export default {
        name: "StyledTablePage",
        data() {
            return {
                nomPartenaire : '',
                partnerName : [],
                showUser: false,
                detailsUser : [],
                modalgenerationloading : false,
                optionsdate: {
                    format: 'YYYY/MM/DD',
                    useCurrent: false,
                },
                ldevis: [],
                du: null,
                au: null,
                listdevis: [],
                modalgeneration: false,
                detailsDevis: [],
                showdevis: false,
                pageOptions: [5, 10, 15],
                username: null,
                data: {},
                html: '',
                Mycounter: 0,
                dismissSecs: 3,
                totalRows: null,
                perPage: 8,
                UpdateOk: false,
                deleteConfirmation: false,
                modalDelete: false,
                dismissCountDown: 0,
                detailsUser: [],
                listuser: null,
                filter: null,
                showuser: false,
                voidField: false,
                isMobile: false,
                ready: false,
                width: 0,
                height: "auto",
                loading: false,
                search: "",
                pagination: {
                    page: 1,
                    size: 100,
                    sizes: [10, 15, 20, 30, 50, 100],
                    layout: "total, ->, prev, pager, next, jumper, sizes",
                    small: false
                },
                list: this.listdevis,//users,
                editMode: false,
                itemsChecked: [],
                dialogUserVisible: false,
                currentId: 0,
                moment
            }
        },
        computed: {
            listFiltered()
            {
                if(this.search.length >= 4){
                    return this.listdevis.filter(obj => {
                        let ctrl = false
                        for (let k in obj)
                        {
                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                ctrl = true
                                /*on le push dans le tableau */
                                //this.listdevis.push(obj)
                            }else{
                                /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
                                if(Array.isArray(obj[k])){
                                    obj[k].filter(nobj=>{
                                        for(let l in nobj){
                                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                            if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                                ctrl = true
                                                /* on le push dans le tableau */
                                                //this.listdevis.push(obj)
                                            }
                                        }
                                    })
                                    /* Si la valeur courante n'est ni String, ni Tableau mais Object */
                                }else if(!Array.isArray(obj[k]) && typeof obj[k]=="object" && obj[k]!=null){
                                    /*== On crée un nouveau tablequ vide ==*/
                                    let my_new_array = []

                                    /*== On push la valeur courante dans le nouveau tableau ==*/
                                    my_new_array.push(obj[k])

                                    /*On refiltre le nouveau tableau atrribut par attribut*/
                                    my_new_array.filter(nobj=>{
                                        for(let l in nobj){
                                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                            if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                                ctrl = true

                                                /* on le push dans le tableau */
                                                //this.listdevis.push(obj)
                                            }
                                        }
                                    })
                                }
                            }
                        }
                        return ctrl
                    })
                }else{
                    return this.listdevis.filter(obj => {
                        let ctrl = false
                        for (let k in obj)
                        {
                            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
                                ctrl = true
                        }
                        return ctrl
                    })
                }

            },

            listSortered() {
                let prop = this.sortingProp
                let order = this.sortingOrder
                return [].concat(
                    this.listFiltered.sort((item1, item2) => {
                        //this.listdevis.sort((item1, item2) => {
                        let val1 = ""
                        let val2 = ""

                        val1 = item1[prop]
                        val2 = item2[prop]
                        if (order === "descending") {
                            return val2 < val1 ? -1 : 1
                        }
                        return val1 < val2 ? -1 : 1
                    })
                )
            },
            listInPage() {
                let from = (this.currentPage - 1) * this.itemPerPage
                let to = from + this.itemPerPage * 1
                //return this.listdevis.slice(from, to)
                return this.listFiltered.slice(from, to)
            },
            total() {
                return this.listdevis.length //listFiltered.length
            },
            currentPage: {
                get() {
                    return this.pagination.page
                },
                set(val) {
                    this.pagination.page = val
                }
            },
            itemPerPage() {
                return this.pagination.size
            },
            selectedItems() {
                return this.itemsChecked.length || 0
            }
        },
        watch: {
            itemPerPage(val) {
                this.ready = false
                this.currentPage = 1

                setTimeout(() => {
                    this.ready = true
                }, 500)
            },
            search(val) {
                this.currentPage = 1
            }
        },
        methods: {
            exportToExcel(){
                Api().get('/export/devis/systeme')
                    .then(resultat => {
                        this.modalgenerationloading = true;
                        let url = resultat.data.lien
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                                window.open(url)), 7000
                        );
                    });
            },

            getPartner(item)
            {
                let partner = ""
                for(let p of item.userid.affiliated_partner)
                {
                   if(p.code_partenaire==item.codep){
                    partner = p.namePartner
                   }
                }
                return partner
            },

            SetDetailUser(details) {
                this.detailsUser = details
                this.showUser = true
            },

            AlertMessage() {
                this.$message({
                    showClose: false,
                    duration : 5000
                });
            },

            detaildevis(item) {
                this.detailsDevis = item
                this.showdevis = true
            },

            // Fonction de download du devis en fichier pdf
            Downloa_devis: function (devis) {
                Api().get('devis/download/' + devis._id)
                    .then(response => {
                        this.modalgenerationloading = true;
                        let url = response.data.lien
                        let WinOpener = null
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                                WinOpener = window.open(),
                                WinOpener.location = url
                        ), 7000);
                    })
            },

            getDevis() {

                if (this.$store.state.user.role == 'agent général') {
                    Api().get('/devis/assureur/' + this.$store.state.user.societe)
                        .then(resultat => {
                            this.listdevis = resultat.data.devis
                            this.totalRows = this.listdevis.length
                        });
                } else if (this.$store.state.user.role == 'Agent') {
                    Api().get('/devis/courtier/' + this.$store.state.user.societe)
                        .then(resultat => {
                            this.listdevis = resultat.data.devis
                            this.totalRows = resultat.data.devis.length
                        });

                } else if (this.$store.state.user.role == 'Assureur') {
                    Api().get('/devis/assureur/' + this.$store.state.user.societe)
                        .then(resultat => {
                            this.listdevis = resultat.data.devis
                            this.totalRows = resultat.data.devis.length
                        });
                } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin') {
                    Api().get('/system/devis/')
                        .then(resultat => {
                            this.listdevis = resultat.data.devis
                            console.log(this.listdevis)
                            this.totalRows = resultat.data.devis.length
                            //this.getPartner()
                        });
                        
                }

            },

            devisDeleteConfirmation(item) {
                this.modalDelete = true
                this.detailsDevis = item
            },

            ArchivateDevis(devis) {
                let id = devis._id
                Api().post('devis/archivate/' + id)
                    .then(resp => {
                        this.getDevis()
                    })
            },

            DeletingDevis(id) {
                Api().delete('devis/delete/' + id)
                    .then(resultat => {
                        if (resultat.data.rep.n == 1) {
                            this.modalDelete = false
                            this.getDevis()
                        }
                    })
                    .catch(err => {})
            },

            FiltreDate() {
                if (this.du && this.au) {
                    switch (store.state.user.role) {
                        case 'Admin':
                            Api().post('devis/filtre/periode', {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listdevis = resultat.data.devis
                                });
                            break;

                        case 'Agent':
                            Api().post('devis/filtre/courtier/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listdevis = resultat.data.devis
                                });
                            break;

                        case 'assureur':
                            Api().post('devis/filtre/assureur/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listdevis = resultat.data.devis
                                });
                            break;
                        case 'agent général':
                            Api().post('devis/filtre/assureur/periode/' + store.state.user.assureur, {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listdevis = resultat.data.devis
                                });
                            break;

                        default:
                            break;
                    }
                }else {
                    this.$message.error('Les deux dates sont obligatoire !', this.AlertMessage)
                }

            },

            toggleDetails(item){
                this.detailsUser = item
                this.editUser = true
            },

            ready: function () {
                var self = this;
                setInterval(function () {
                    self.$data.ticker = Date.now();
                }, 1000);
            },

            getUserList:function(){
                switch (this.$store.state.user.role) {
                    case 'Admin':
                        Api().get('/user/frontoffice/')
                            .then( resultat => {
                                this.listuser = resultat.data
                                this.totalRows = resultat.data.length
                            });
                        break;


                    case 'assureur':
                        Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
                            .then( resultat => {
                                this.listuser = resultat.data
                                this.totalRows = resultat.data.length
                            });
                        break;

                    default:
                        break;
                }
            },

            DateFormater: function (TheDate) {
                if (TheDate) {
                    return dayjs(TheDate).format('DD-MM-YYYY')
                }
            },


            calcDims() {
                const tableWrapper = document.getElementById("table-wrapper")
                if (tableWrapper) this.width = tableWrapper.clientWidth

                if (!this.isMobile && tableWrapper) {
                    this.height = tableWrapper.clientHeight - 44
                }

                if (this.width < 480) {
                    this.pagination.small = true
                    this.pagination.layout = "prev, pager, next"
                } else if (this.width >= 480 && this.width < 700) {
                    this.pagination.small = false
                    this.pagination.layout = "prev, pager, next, ->, sizes"
                } else {
                    this.pagination.small = false
                    this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
                }

                this.ready = true
            },

            init() {
                if (window.innerWidth <= 768) this.isMobile = true
            }
        },

        created() {
            this.init()
            this.getUserList()
            this.getDevis()
        },
        mounted() {
            if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
                console.log('okay')
            } else {
                this.$router.go(this.$router.push('/login'))
            }

            //ie fix
            if (!window.Number.parseInt) window.Number.parseInt = parseInt

            this.calcDims()
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/_variables";

    .page-table {
        &.overflow {
            overflow: auto;
        }

        .toolbar-box {
            &.hidden {
                visibility: hidden;
            }
        }

        .table-box {
            overflow: hidden;

            &.hidden {
                visibility: hidden;
            }
        }
    }
</style>

<style lang="scss">
    @import "../../../assets/scss/_variables";

    .button-filtre{
        padding: 12px!important;
        margin : 0 15px!important;
        background: #C0C7CF!important;
        border:none!important;
        color:#fff!important;
    }
    .page-table {
        .toolbar-box {
            margin-bottom: 10px;

            .card-shadow--medium {
                &.el-input,
                &.el-select {
                    border-radius: 4px;

                    input {
                        border-color: transparent;
                        background-color: lighten($background-color, 2%);
                        color: $text-color;

                        &:hover {
                            border-color: #c0c4cc;
                        }
                        &:focus {
                            border-color: $text-color-accent;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .page-table {
            .toolbar-box {
                display: block;
                overflow: hidden;
                font-size: 80%;
                padding-bottom: 10px;

                & > * {
                    display: inline-block;
                    min-width: 120px;
                    height: 22px;
                    //background: rgba(0, 0, 0, 0.04);
                    margin-bottom: 16px;
                }
            }
        }
    }
</style>
