<template>
    <vue-scroll class="page-devis-voyage" >
        <div class="page-header">
			<h1>Création de devis <span style="color:#ffa409">Voyage</span>
				<theme-picker style="float:right"></theme-picker>
			</h1>
		</div>

        <div v-if="!isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:15px">
                <el-row :gutter="15" type="flex" class="row-bg" justify="center">
                      <el-col :span="12">
                        <div>
                            <label>Pays d'origine</label>
                            <el-input placeholder="Pays d'origine" v-model="pays_domicile" readonly>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div>
                            <label>Zone de déstination</label>
                            <el-select style="width:100%" v-model="destination" placeholder="Selectionner un pays">
                                <el-option v-for="(items , idx) in zone_destination" :key="idx" :label="items" :value="items">{{items}}</el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div style="margin-top:15px">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <div class="grid-content bg-purple" v-if="categorie !== '2_roues'">
                            <label>Date de départ</label>
                            <el-date-picker
                                style="width: 100%!important;"
                                v-model="dateDepart"
                                type="date"
                                format="yyyy/MM/dd"
                                placeholder="Choississez une date"
                                :picker-options="dateAfterToday">
                            </el-date-picker>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Date de retour</label>
                           <el-date-picker
                                style="width: 100%!important;"
                                @change="DurationCalculation"
                                v-model="dateArrivee"
                                format="yyyy/MM/dd"
                                type="date"
                                placeholder="Choississez une date"
                                :picker-options="dateAfterToday"
                                >
                            </el-date-picker>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Nombre de passagers</label>
                            <el-input type="number" min="1" placeholder="Nombre de passager" v-model="nbpassager" @change="remplissage_passager">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <el-row :gutter="20" type="flex" justify="center" style="margin-top:15px;">
                <el-col :span="24">
                    <div class="grid-content bg-purple-dark">
                        <el-radio v-for="(assur) in listeAssureurs" :key="assur.societe" v-model="assureurs" :label="assur.societe">
                            <template>
                                <img width="50" height="40" :src="assur.logo">
                            </template>
                        </el-radio>
                    </div>
                </el-col>
            </el-row>

            <div style="margin-top:15px">
                <el-button @click="Simulatevoyage()" type="primary" round>Simuler</el-button>
            </div>
        </div>

        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="quote_simulate!=null">
           <div class="text-center">
               <p>Prime Nette : <span class="bolder-text">{{ quote_simulate.prime_nette }}</span> </p>
               <p>Accessoires : <span class="bolder-text">{{ quote_simulate.accessoire }}</span></p>
               <p>TAXE TVA : <span class="bolder-text">{{ quote_simulate.taxe }}</span></p>
               <p>Prime TTC : <span class="bolder-text">{{ quote_simulate.prime_ttc }}</span></p>
           </div>
           <el-button @click="customer_phone_popup=true" type="primary" round>Payer</el-button>
        </div>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise" value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
                   <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
                 <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise" value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog title="Liste des passager" :visible.sync="popupListePassager">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple" v-if="categorie !== '2_roues'">
                            <label>Date de naissance du passager</label>
                            <el-date-picker
                                style="width: 100%!important;"
                                v-model="datenaissancePassager"
                                type="date"
                                placeholder="Choississez une date"
                                :picker-options="dateBeforeToday">
                            </el-date-picker>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button @click="CreateListPassager" type="primary" round>Ajouter</el-button>
                </div>  
            </el-form>

            <div style="margin-top:15px">
                 <el-table :data="listPassager" style="width: 100%">
                    <el-table-column
                    fixed
                    prop="adulte_enfant"
                    label="Adulte / Enfant"
                    width="300">
                    </el-table-column>
                    <el-table-column prop="age_passager" label="Age passager" width="300">
                        <template slot-scope ="scope">
                            <span class="date">{{ scope.row.age_passager }} an(s)</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="margin-top:15px" v-show="nexted">
                 <el-button type="primary" round @click="popupListePassager=false">Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog title="Numéro de téléphone du client" :visible.sync="customer_phone_popup">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label> Numéro de téléphone du client </label>
                            <el-input placeholder="téléphone du client" v-model="pre_contract_data.customer_phone" required></el-input>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button @click="createPreContratvoyage()" type="primary" round>Continuer</el-button>
                </div>  
            </el-form>
        </el-dialog>
    </vue-scroll>
</template>

<script>


//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import pays from '../../../common/countries'
//fin importation assuraf
import customPassword from "../../../services/password_generator"

const validatePhone = phone => {
  if(!phone.length){
    return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
  }else{
    return { valid: true, error: null };
  }

};

const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "L'adresse e-mail est obligatoire!"};
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};

export default {
    name: "devis_voyage",
    
    data() {
        return {
            zone_destination:['SCHENGEN', 'MOYENORIENT', 'Afrique', 'RESTMONDE'],
            customer_phone_popup :false,
            pre_contract_data:{
                branche: "Voyage",
                assureurs: "",
                customer_phone: "",
                createur: this.$store.state.user._id,
                destination :null,
                dateEffet:"",
                dateEcheance: "",
                dateSouscription: "",
                taxe: "",
                prime_ttc: "",
                prime_nette: "",
                accessoires: ""
            },
            customer_phone : "",
            quote_simulate:null,
            modalgenerationloading : false,
            isMobile : false,
            errors : {},
            dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            dateAfterDateDepart : {
                disabledDate(time) {
                    return time.getTime() > this.getTime(this.dateDepart)
                }
            },
            remboursable:null,
            optionsdateDepart: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },

            optionsdateArrivee: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },
            PassengerBirthOptionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            countriesSn: 'Sénégal',
            telephoneC: null,
            showId: false,
            nom: null,
            prenom: null,
            email: null,
            duree: null,
            profession:null,
            genre:null,
            datenaissance: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            emailclient: null,
            emailC: this.emailclient,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            hideForm: false,
            popupListePassager: false,
            steps: [{
                    label: 'Etape 1',
                    completed: false,
                },
                {
                    label: 'Etape 2',
                    completed: false,
                },
                {
                    label: 'Etape 3',
                    completed: false,
                },
            ],
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            adulte_enfant: null,
            seen: true,
            selected: [],
            nexted: false,
            selectpays: '',
            ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
            }, // Customized options for success toast
            ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'error'
            }, // Customized options for error toast
            listpays: pays,
            dateDepart: null,
            dateEffet: null,
            dateEcheance: null,
            pays_domicile: null,
            dateArrivee: null,
            nbpassager: null,
            garantie: [],
            age: null,
            assureurs: '',
            listpaysprime: [],
            usersearch: [],
            telephoneclient: '',
            destination: null,
            listeAssureurs: [],
            listvoyage: [],
            listPassager: [],
            datenaissancePassager: null,
            enteteTabPassager: [{
                    key: 'adulte_enfant',
                    label: 'Adulte / Enfant'
                },
                {
                    key: 'age_passager',
                    label: 'Age passager'
                },
            ],

            items: [{
                    text: 'homme'
                },
                {
                    text: 'femme'
                },
            ],
         
            NumberInput : false,
            EmailInput : false,
            
            radio: '1',
           
       
           
            optionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },
            optionsdateMEC: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                maxDate: new Date(),
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            e1: 0,
           
            telephoneclient: null,
            emailclient: null,
            emailC: this.emailclient,
            telephoneC: this.telephoneclient,
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            showId: false,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            nom: null,
            prenom: null,
            email: null,
            datenaissance: null,
            profession: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            seen: true,
            hideDive_vehicule: null,
           
            password1: null,
           
            garanties: [],
        
         
            voidField: false,
          
            Deb: null,
            selectedcarbu: '',
           
            types: [
                'text',
                'text',
                'number',
            ],
            show: true,
            //fin initialisation des models d'assuraf
            chart: null,
            pie: null,
            gridData: [],
            userRole: null
        }
    },
    mounted() {
        this.pays_domicile = this.countriesSn;
        this.getUserRoles()
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') 
        {
            this.getListePartenaire();
            this.getListePays()
            this.duree = 1
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },
   
    methods: {
        getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
        },

        getObjectPays(pays){
            let founder = null
            for(let v of this.listpays){
                if(v.PAYS==pays){
                    founder = v
                }
            }
            return founder
        },

        init() {
        if (window.innerWidth <= 768) this.isMobile = true
        },
        
        Simulatevoyage: function () 
        {
            if(this.dateDepart && this.dateArrivee && this.assureurs!='')
            {
                 let voyage = {
                    assureurs: this.assureurs,
                    nbpassager: this.nbpassager,
                    dateDepart: this.dateDepart,
                    dateArrivee: this.dateArrivee,
                    duree: this.duree,
                    destination: this.destination,
                    dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                    pays_domicile: this.pays_domicile,
                    listPassager: this.listPassager,
                    branche: 'Voyage',
                    mode_remboursement: this.remboursable,
                }
                
                Api().post('micro/contrat/voyage/simulate', voyage)
                    .then(resultat => {
                        this.quote_simulate                = resultat.data
                        this.pre_contract_data.branche          = "Voyage",
                        this.pre_contract_data.assureurs        = this.assureurs,
                        this.pre_contract_data.customer_phone   = this.customer_phone,
                        this.pre_contract_data.createur         = this.$store.state.user._id,
                        this.pre_contract_data.dateEffet        = dayjs(this.dateDepart).format('YYYY-MM-DD'),
                        this.pre_contract_data.dateEcheance     =  dayjs(this.dateArrivee).format('YYYY-MM-DD'),
                        this.pre_contract_data.dateSouscription = dayjs(new Date()).format('YYYY-MM-DD')
                        this.pre_contract_data.taxe             =  resultat.data.taxe,
                        this.pre_contract_data.prime_ttc        = resultat.data.prime_ttc,
                        this.pre_contract_data.prime_nette      =  resultat.data.prime_nette,
                        this.pre_contract_data.accessoires      =  resultat.data.accessoire
                        this.pre_contract_data.destination      = this.destination
                    })
            }else{
                this.$message.error('Choisissez au minimum un assureur !', this.AlertMessage)
            }
        },

        createPreContratvoyage(){
            if(this.pre_contract_data.customer_phone!="" &&  this.pre_contract_data.customer_phone!=null)
            {
                Api().post('/micro/contrat/voyage', this.pre_contract_data)
                .then((result) => {
                    if(result.data.status==200){
                        this.pre_contract_data.branche          = "",
                        this.pre_contract_data.assureurs        = "",
                        this.pre_contract_data.customer_phone   = "",
                        this.pre_contract_data.createur         = "",
                        this.pre_contract_data.dateEffet        = "",
                        this.pre_contract_data.dateEcheance     = "",
                        this.pre_contract_data.dateSouscription = ""
                        this.pre_contract_data.taxe             = "",
                        this.pre_contract_data.prime_ttc        = "",
                        this.pre_contract_data.prime_nette      = "",
                        this.pre_contract_data.accessoires      = ""

                        this.assureurs      = "",
                        this.nbpassager     = "",
                        this.dateDepart     = "",
                        this.dateArrivee    = "",
                        this.duree          = "",
                        this.destination    = "",
                        this.listPassager   = "",
                        this.remboursable   = "",
                        this.$message.success(result.data.message, this.AlertMessage)
                    }
                }).catch((err) => {
                    this.$message.error('Erreur Serveur !', this.AlertMessage)
                }); 
            }else{
                this.$message.error('Le numéro de téléphone du client est obligatoire !', this.AlertMessage)
            }
        },

        getListePays: function () {
            Api().get('/pays')
                .then(response => {
                    this.listpays = response.data
                })
                .catch(err => {
                    err
                })
        },

        CreateListPassager: function () 
        {
            if (this.datenaissancePassager) {
                let ageP = this.PassengerAgeCalculation()
                if (ageP <= 18) {
                    this.adulte_enfant = "enfant"
                } else if (ageP > 18) {
                    this.adulte_enfant = "adulte"
                }

                if (this.listPassager.length < this.nbpassager) {
                    
                    let Unpassager = {
                        'adulte_enfant': this.adulte_enfant,
                        'age_passager': ageP,
                        'dateNaissance': this.datenaissancePassager
                    }

                    this.listPassager.push(Unpassager)
                    this.age = null
                    this.adulte_enfant = null,
                    this.datenaissancePassager = null

                    if (this.listPassager.length <= this.nbpassager) {
                        this.nexted = true
                    }

                } else {
                    this.$toasted.show('Tous les passagers ont été ajouté !', this.ToastTabError)
                }

            } else {
                this.$toasted.show('Remplissez tous les champs vide !', this.ToastTabError)
            }
        },
        
        remplissage_passager() {
            this.popupListePassager = true
        },



          /*Passenger Age calculation based on passenger birthday*/
        PassengerAgeCalculation() {
            let TodayDate = new Date()
            this.age = dayjs(TodayDate).diff(this.datenaissancePassager, 'year')

            let PassengerObject = {
                birthday: this.PassengerBirthday,
                age: this.age
            }
            localStorage.setItem('passenger-birthady-age', JSON.stringify(PassengerObject))
            return PassengerObject.age
        },

        DurationCalculation() {
            if(dayjs(this.dateArrivee).diff(this.dateDepart, 'day') > 0){
                this.dateEffet = this.dateDepart
                this.dateEcheance = this.dateArrivee
                let date_tmp =  dayjs(this.dateArrivee).diff(this.dateDepart, 'day')
                this.duree = date_tmp + 1  //dayjs(this.dateArrivee).diff(this.dateDepart, 'day')
            }else{
                this.$message.error('Erreur, date postérieure à la date de départ !', this.AlertMessage)
                this.dateArrivee = null
            }
            
        },


        /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },
        AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },

     genericError(msg){
        this.$notify({
			title: 'Erreur',
			message: msg,
			position: 'top-right',
			type: 'error'
		});
     },

     errorNotif(){
			this.$notify({
				title: 'Erreur',
				message: 'Numéro obligatoire !',
				position: 'top-right',
				type: 'error'
			});
		},
        errorNotifCreateUser(){
			this.$notify({
				title: 'Erreur',
				message: 'Tout les Champs sont obligatoires !',
				position: 'top-right',
				type: 'error'
			});
		},
        successNotifCreateUser(){
			this.$notify({
				title: 'Success',
				message: 'Utilisateur crée avec succés !',
				position: 'top-right',
				type: 'succes'
			});
		},
        check_client_search(){
            if(this.clientsearch == 'téléphone'){
                this.NumberInput = true 
                this.EmailInput = false
                this.emailclient = null
                this.clientOk = false
                this.clientnonOk = false
            } else if(this.clientsearch == 'email'){
                this.NumberInput = false 
                this.EmailInput = true
                this.telephoneclient = null
                this.clientOk = false
                this.clientnonOk = false
            }
        },

      rechercherClient() {
        if(this.telephoneclient){
          const validPhone = validatePhone(this.telephoneclient);
          this.errors.phone = validPhone.error;

          if(validPhone.valid == true){
            Api().get('/user/phone/' + this.telephoneclient)
                .then(resultat => {
                  if (resultat.data.telephone_port === this.telephoneclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;
                    this.$store.dispatch('setClient', resultat.data)
                  }

                })
                .catch(e => {
                  this.clientnonOk = true
                  this.clientOk = false
                  this.telephoneC = this.telephoneclient;
                })
          } else {
            this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
          }
        }else {
          this.$message.error('Numéro de obligatoire !', this.AlertMessage)
        }


      },

      rechercheClientByMail(){
        if(this.emailclient){

          const validEmail = validateEmail(this.emailclient);
          this.errors.email = validEmail.error;

          if(validEmail.valid == true){
            Api().get('/user/email/' +this.emailclient)
                .then(resultat =>{
                  if (resultat.data.email === this.emailclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;
                    this.$store.dispatch('setClient', resultat.data)
                  }
                }).catch(e => {
              this.clientnonOk = true
              this.clientOk = false
              this.emailC = this.emailclient;
            })
          } else {
            this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
          }
        }else {
          this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

        }
      },

        yearsDiff() {
            const date1 = dayjs(this.dateCircule);
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            let daydiff = date2.diff(date1, 'days');
            this.age = daydiff

           if (this.age <= 29) {
                this.an = this.annee_mois = 'jour(s)'
            } else if (this.age >= 30, this.age <= 360) {
                let year = Math.floor(this.age / 30)
                this.age = year;
                this.an = this.annee_mois = 'mois'
            } else if (this.age >= 360) {
                let year = Math.floor(this.age / 360)
                this.age = year;
                this.an = this.annee_mois = 'an(s)'
            }

        },

        showidclient() {
            this.showId = false
            this.showconfirmclient = true
            this.shownameclient = false

        },

        hidesearch() {
            this.hidesearchClient = false
            this.e1 = 1
        },

        rechercherbyphone() {
            if (this.usersearch.telephone_port === this.telephoneclient) {
                this.clientOk = true
                this.clientnonOk = false

            }
            if (this.usersearch.telephone_port !== this.telephoneclient) {
                this.clientnonOk = true
                this.clientOk = false
                this.telephoneC = this.telephoneclient;

            }
        },

        onReset(evt) {
            evt.preventDefault();
            this.user.email = '';
            this.user.name = '';
            this.user.food = null;
            this.user.checked = [];
            this.show = false;
            this.$nextTick(() => {
                this.show = true
            });
        },


        //liste de touts les partenaires
        getListePartenaire: function () 
        {
            if (this.$store.state.user.role == 'Agent')
            {
                Api().post('/branchecourtier/get/partenaire/',{userid:this.$store.state.user._id, branche:"Voyage"})
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires[0].assureurs
                    }).catch(err => {})
            } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin') 
            {
                Api().get('/partenaire/partners/voyage')
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires
                    })
            } else if (this.$store.state.user.role == 'Assureur') 
            {
                Api().get('/partenaire/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire
                    })
            }

        },

        HubspotSend(param)
        {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param)
        {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        // Creation d'un user avant de faire son devis
        createuser: function () {

            this.generatePassword()
            if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) 
            {
                const user = {
                        nom: this.nom,
                        prenom: this.prenom,
                        datenaissance: this.datenaissance,
                        profession: this.profession,
                        adresse: this.adresse,
                        sexe: this.genre,
                        telephone_port: this.telephoneclient,
                        email: this.emailclient,
                        password: this.password1,
                        confirmPass: this.password1,
                        role: this.userRole,
                        createdby:store.state.user._id,
                        photo: this.file,
                        piece_identite: this.piece_identite,
                        numero_piece: this.numero_piece,
                         plateforme : 'Dash',
                        created: dayjs(new Date()).format('YYYY-MM-DD')
                }
                Api().post('user/register', user)
                        .then(resultat => {
                            this.HubspotSend(user)
                            this.MailChimpInscriptionCompte(user)
                            if (resultat.status == 200) {
                                if(resultat.data.numero){
                                    this.$message.error(resultat.data.msg, this.AlertMessage)
                                }else{
                                    this.$store.dispatch('setClient', resultat.data.data)
                                    this.usersearch = resultat.data.data
                                    this.AddUser = false
                                    this.clientnonOk = false
                                    this.hidesearchClient = false
                                    this.e1 = 1
                                }
                            } else {
                                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                            }

                })
                .catch(err => {
                    this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                })
               
            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }

        },

        generatePassword: function () {
            this.password1 = customPassword.customPassword(this.nom, this.prenom)
        },

         //calclu date echéance
        dateDiff: function () {
            if (this.dateEffet === '' & this.duree === '') this.dateEcheance = ''
            this.dateEcheance = dayjs(this.dateEffet).add(this.duree, 'day').format('YYYY-MM-DD')
        },

        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*==========CETTE PARTIE EST SPECIALEMENT POUR UNE SERIE DE FONCTION PERMETTANT DE VALIDER LES MODELS POUR CHAQUE STEPPER=========*/
        //Validation des elements du Stepper 1
        Stepper1Validator() {
            if (this.destination) {
                this.e1 = 2
            } else {
                this.$message.error('Pays de destination obligatoire !', this.AlertMessage)
            }
        },

        //Validation des ellements du Stepper 2
        Stepper2Validator() {
            if (this.dateDepart) {
                if(this.dateArrivee){
                    if(this.nbpassager){
                        if(this.remboursable){
                            this.e1 = 3
                            this.dateEffet = this.dateDepart
                        }else {
                            this.$message.error('Choix remboursement obligatoire !', this.AlertMessage)
                        }
                    }else {
                        this.$message.error('Nombre de passager obligatoire !', this.AlertMessage)
                    }
                }else {
                    this.$message.error("Date d'arrivée obligatoire !", this.AlertMessage)
                }
            } else {
                this.$message.error('Date de départ obligatoire !', this.AlertMessage)
            }
        },

        //Validation des elements du Stepper 3
        Stepper3Validator() {
            if (this.categorie !== '2_roues') {
                if (this.marque) {
                    if (this.model) {
                        this.e1 = 4
                    } else {
                        this.$message.error('Veuillez choisir le modèle du véhicule !', this.AlertMessage)
                    }
                } else {
                    this.$message.error('Veuillez choisir la marque du véhicule !', this.AlertMessage)
                }
            } else {
                if (this.marque) {
                    if (this.model) {
                        this.e1 = 5
                    } else {
                        this.$message.error('Veuillez choisir le modèle du véhicule !', this.AlertMessage)
                    }
                } else {
                    this.$message.error('Veuillez choisir la marque du véhicule !', this.AlertMessage)
                }
            }

        },

        testVar:function(val){
             if (val!=null)
            {
                return Number(val.replace(/[^0-9.-]+/g, ""));
            } else {
                return "0";
            }
        },



        __resizeHanlder: _.throttle(function(e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700),

        
    },
     created() {
        this.init()
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  .bolder-text{
    font-weight: bold;
  }
.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }
                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }
                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }
                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
