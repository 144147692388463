<template>
  <vue-scroll class="create-back-user">
    <div class="page-header">
      <h1>Nouvel<span style="color:#ffa409"> utilisateur BackOffice</span>
      </h1>
    </div>
    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div style="margin-top:15px">
        <pre> {{ listRoles }}</pre>

        <el-row :gutter="15">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nom<span class="required">(*)</span> </label>
              <el-input placeholder="Votre nom" v-model="nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Prénom<span class="required">(*)</span> </label>
              <el-input placeholder="Votre prénom" v-model="prenom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Adresse</label>
              <el-input placeholder="Votre adresse" v-model="adresse">
              </el-input>
            </div>
          </el-col>

        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone portable<span class="required">(*)</span> </label>
              <el-input type="number" placeholder="Numéro téléphone portable" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Adresse email<span class="required">(*)</span> </label>
              <el-input placeholder="Adresse email" v-model="email">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date de naissance</label>
              <el-date-picker style="width: 100%!important;" v-model="datenaissance" type="date"
                placeholder="Choississez une date">
              </el-date-picker>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone bureau</label>
              <el-input placeholder="Téléphone bureau" v-model="telephone_bur">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Sexe<span class="required">(*)</span> </label>
              <el-select style="width: 100%" v-model="genre" placeholder="Selectionner">
                <el-option value="homme">Homme</el-option>
                <el-option value="femme">Femme</el-option>
                <el-option label="Entreprise" value="entreprise"></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="grid-content bg-purple"  v-if="this.$store.state.user.role == 'Agent' || this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
              <label>Rôle<span class="required">(*)</span> </label>
              <el-select style="width: 100%" v-model="userRole" placeholder="Selectionner">
                <el-option v-for="role in listRoles" :key="role._id" :label="role.name" :value="role._id" />
              </el-select>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Type de pièce</label>
              <el-select style="width: 100%" v-model="type_piece" placeholder="Selectionner">
                <el-option label="Passeport" value="passeport">Passeport</el-option>
                <el-option label="CNI" value="Carte CI">CNI</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Numéro pièce d'identité</label>
              <el-input placeholder="Numéro" v-model="numero_piece">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Profession</label>
              <el-input placeholder="profession" v-model="profession">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px" v-if="genre && genre != 'entreprise'">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nombre d'enfants</label>
              <el-input type="number" placeholder="Nombre d'enfants" v-model="nombre_enfant">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Statut matrimonial</label>
              <el-select style="width: 100%" v-model="statut_marial" placeholder="Selectionner">
                <el-option value="marié">Marié(e)</el-option>
                <el-option value="célibataire">Célibataire</el-option>
                <el-option value="veuf">veuf(ve)</el-option>
                <el-option value="divorcé">divorcé(e)</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Photo (.png .jpg .jpeg)</label>
              <el-input type="file" placeholder="Téléphone bureau" v-model="file" ref="file-input">
              </el-input>
            </div>
          </el-col>

        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8" v-if="modalSocieteAdmin == true" v-show="modalSocieteAdmin == true">
            <div class="grid-content bg-purple">
              <label>Lier l'utilisateur à un partenaire<span class="required">(*)</span> </label>
              <el-select style="width: 100%;" v-model="societeAdmin" placeholder="Selectionner"
                v-if="this.$store.state.user.role == 'Agent'">
                <el-option :label="societeAdmin.societe" :value="societeAdmin._id">
                  {{ societeAdmin.societe }}
                </el-option>
              </el-select>
              <el-select style="width: 100%" v-model="societeAdmin" placeholder="Selectionner" v-else>
                <el-option v-for="assur in listeAssureurs" :key="assur.id" :label="assur.societe" :value="assur.id">
                  {{ assur.societe }}
                </el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8" v-if="modalSocieteAdmin == true" v-show="modalSocieteAdmin == true">
            <div class="grid-content bg-purple">
              <label>agent administrateur</label>
              <el-select style="width: 100%;" v-model="agent_admin" placeholder="Selectionner">
                <el-option label="Oui" :value="true"> </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button @click="createuser()" type="primary" round>Créer</el-button>
        </div>
      </div>

    </div>
    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom<span class="required">(*)</span> </label>
              <el-input placeholder="Votre nom" v-model="nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Prénom<span class="required">(*)</span> </label>
              <el-input placeholder="Votre prénom" v-model="prenom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Adresse</label>
              <el-input placeholder="Votre adresse" v-model="adresse">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone portable<span class="required">(*)</span> </label>
              <el-input type="number" placeholder="Numéro téléphone portable" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Adresse email<span class="required">(*)</span> </label>
              <el-input placeholder="Adresse email" v-model="email">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Date de naissance</label>
              <el-date-picker style="width: 100%!important;" v-model="datenaissance" type="date"
                placeholder="Choississez une date">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone bureau</label>
              <el-input placeholder="Téléphone bureau" v-model="telephone_bur">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Sexe<span class="required">(*)</span> </label>
              <el-select style="width: 100%" v-model="genre" placeholder="Selectionner">
                <el-option value="homme">Homme</el-option>
                <el-option value="femme">Femme</el-option>
                <el-option label="Entreprise" value="entreprise"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple"
              v-show="this.$store.state.user.role == 'Agent' || this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
              <label>Rôle<span class="required">(*)</span> </label>
              <el-select style="width: 100%" v-model="userRole" placeholder="Selectionner">
                <el-option v-for="role in listRoles" :key="role._id" :label="role.name" :value="role._id" />
              </el-select>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Type de pièce</label>
              <el-select style="width: 100%" v-model="type_piece" placeholder="Selectionner">
                <el-option label="Passeport" value="passeport">Passeport</el-option>
                <el-option label="CNI" value="Carte CI">CNI</el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Numéro pièce d'identité</label>
              <el-input placeholder="Numéro" v-model="numero_piece">
              </el-input>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Profession</label>
              <el-input placeholder="profession" v-model="profession">
              </el-input>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Nombre d'enfants</label>
              <el-input type="number" placeholder="Nombre d'enfants" v-model="nombre_enfant">
              </el-input>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Statut matrimonial</label>
              <el-select style="width: 100%" v-model="statut_marial" placeholder="Selectionner">
                <el-option value="marié">Marié(e)</el-option>
                <el-option value="célibataire">Célibataire</el-option>
                <el-option value="veuf" selected>veuf(ve)</el-option>
                <el-option value="divorcé">divorcé(e)</el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Photo (.png .jpg .jpeg)</label>
              <el-input type="file" placeholder="Téléphone bureau" v-model="file" ref="file-input">
              </el-input>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px" v-if="modalSocieteAdmin == true" v-show="modalSocieteAdmin == true">
            <div class="grid-content bg-purple">
              <label>Lier l'utilisateur à un partenaire<span class="required">(*)</span> </label>
              <el-select style="width: 100%;" v-model="societeAdmin" placeholder="Selectionner"
                v-if="this.$store.state.user.role == 'Agent'">
                <el-option :label="societeAdmin.societe" :value="societeAdmin._id">
                  {{ societeAdmin.societe }}
                </el-option>
              </el-select>
              <el-select style="width: 100%;" v-model="societeAdmin" placeholder="Selectionner" v-else>
                <el-option></el-option>
                <el-option v-for="assur in listeAssureurs" :key="assur.id" :label="assur.societe" :value="assur.id">
                  {{ assur.societe }}
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px" v-if="modalSocieteAdmin == true" v-show="modalSocieteAdmin == true">
            <div class="grid-content bg-purple">
              <label>Agent administrateur<span class="required">(*)</span> </label>
              <el-select style="width: 100%;" v-model="agent_admin" placeholder="Selectionner">
                <el-option label="OUI" :value="true"> </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button @click="createuser()" type="primary" round>Créer</el-button>
        </div>
      </div>
      <small style="color: red;font-style: italic;">(*) champs obligatoires </small>
    </div>

    <el-dialog v-if="modalSociete" width="70%!important" title="Branche et assureurs" :visible.sync="modalSociete"
      :before-close="handleClose">

      <el-row :gutter="15" style="margin-top: 15px">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <label>Choisir une branche</label>
            <el-select style="width: 100%" v-model="Selectedbranche" placeholder="Selectionner">
              <el-option v-for="brancheassur in branchelist" :key="brancheassur.branche" :label="brancheassur.branche"
                :value="brancheassur.branche" />
            </el-select>
          </div>

        </el-col>

        <el-col :span="12">
          <div class="grid-content bg-purple">
            <label>Choisir les assureurs</label>
            <el-select style="width: 100%!important;" v-model="selectedAssureur" multiple filterable allow-create
              default-first-option placeholder="Selectionner assureur(s)">
              <el-option v-for="items in allAssureurs" :key="items.id" :label="items.societe"
                :value="items">
              </el-option>
            </el-select> 
            {{ selectedAssureur }}
          </div>
        </el-col>
        <el-col :span="24">
          <div class="grid-content bg-purple" style="margin-top:15px">
            <el-button @click="pushConfig()" type="primary" round>Créer</el-button>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="mt-15">
          <el-table :data="configUserDevisAssureur" style="width: 100%" :height="height">
            <el-table-column label="Branche" min-width="200" prop="branche"></el-table-column>
            <el-table-column label="Assureur" min-width="200" prop="assureurs">
              <template slot-scope="scope">
                <ul>
                  <li v-for="(item, index) in scope.row.assureurs" :key="index">
                    {{ item }}
                  </li>
                </ul>
              </template>
            </el-table-column>

          </el-table>
          <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
            :total="totalContrat"></el-pagination>

        </el-col>
        <el-col :span="24">
          <div class="grid-content bg-purple" style="margin-top:15px">
            <el-button @click="valideBrancheAgent()" type="primary" round>Valider</el-button>
          </div>
        </el-col>
      </el-row>

    </el-dialog>
  </vue-scroll>
</template>

<script>
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf
import customPassword from "../../../services/password_generator"

export default {
  name: "creer_assureur",
  data() {
    return {
      allAssureurs: [],
      assureofbranche: [],
      ready: false,
      modalSociete: false,
      agent_admin: true,
      listRoles: null,
      isMobile: false,
      Selectedbranche: null,
      selectedAssureur: [],
      configUserDevisAssureur: [],
      assureurs: [],
      branchelist: null,
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      file: null,
      debounceTimer: 500,
      saltLength: 10,
      result: "",
      calculating: false,
      nom: '',
      prenom: '',
      email: '',
      datenaissance: '',
      NewUserFornewCourtier: null,
      genre: null,
      type_user: '',
      listeAssureurs: null,
      adresse: '',
      telephone_port: '',
      telephone_bur: '',
      type_piece: null,
      societeAdmin: null,
      modalSocieteAdmin: false,
      numero_piece: '',
      pseudo: '',
      password1: null,
      userRole: null,
      ToastTabSucess: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'success',
        className: 'custom-success-class'
      }, // Customized options for success toast
      ToastTabError: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'error'
      }, // Customized options for error toast
      mode_retrait_contrat: '',
      adresse_livraison_contrat: '',
      validite_piece: '',
      statut_marial: null,
      nombre_enfant: '',
      profession: '',
      gofor: '',
      lastCourtierAdded: null,
      listuser: [],
      configHead: [
        { key: "branche", label: "branche" },
        { key: "assureurs", label: "assureur" }
      ],
      choosenRole: null,
      voidField: false,
      Deb: null,
      selectedcarbu: '',
      types: [
        'text',
        'text',
        'number',
      ],
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: [],
      height: "auto",
    }
  },
  mounted() {
    
    if (this.$store.state.isUserLoggedIn && this.$store.state.user.role !== 'User') {
      if (this.$store.state.Newcourtier) {
        this.lastCourtierAdded = this.$store.state.Newcourtier
      }
      this.getListPartenaire()
      this.getAllbranche()
    } else {
      this.$router.go(this.$router.push('/login'))
    }
  },

  watch: {
    userRole: function (el) {
      const val = this.listRoles.find(element => element._id == el);
      if (store.state.user.role == 'Agent' || store.state.user.role == 'Admin' || store.state.user.role == "SuperAdmin") {
        if (val.name.toUpperCase() == 'AGENT' || val.name.toUpperCase() == 'ASSUREUR') {
          this.modalSocieteAdmin = true
          // this.modalSociete = true
        } else {
          this.modalSocieteAdmin = false
          // this.modalSociete = false
        }
        this.choosenRole = val
      }
    },
    
  },  

  methods: {

    valideBrancheAgent() {

      if (this.$store.state.user.role == "Agent") (
        Api().get('/partenaire/courtier/' + this.$store.state.user.societe)
          .then(response => {
            this.societeAdmin = response.data
            this.$message.success('configution d\'assureurs par branche reussie !', this.AlertMessage)

          })
      )
      this.modalSociete = false
    },


    handleClose(done) {
      const ok = confirm('Etes vous sur de vouloir fermer le dialog ?')
      if (ok) {
        this.userRole = null
        done()
      }
    },

    getUserRoles() {
      Api().get('/roles/all')
        .then(response => {
          this.listRoles = response.data.AllRoles
          const u = this.listRoles.find(r => r.name === "User")

          if (u !== undefined) {
            this.listRoles.splice(this.listRoles.indexOf(u), 1)
          }

        }).catch(err => { console.log("failed getAllUserRoles"); })
    },


    generateBrancheAssurAgent(userid) {
      Api().post("/branchecourtier/assuragent/create", { userid: userid })
        .then((result) => {
          console.log(JSON.strinfy(result))
        }).catch((err) => { console.log("Erreur sur la génération de menu") });
    },

    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },

    AlertMessage() {
      this.$message({ showClose: false, duration: 5000 });
    },

    setUserid(id) {
      for (let v of this.configUserDevisAssureur) {
        v.userid = id
      }
      return 1
    },

    pushConfig() {
      if (this.Selectedbranche && this.selectedAssureur) {
        this.configUserDevisAssureur.push({
          branche: this.Selectedbranche,
          assureurs: this.selectedAssureur
        })
        this.Selectedbranche = null
        this.selectedAssureur = []
      }
    },

    getListPartenaire: function () {
      if (this.$store.state.user.role == 'Agent') {
        Api().post('/branchecourtier/get/all', { userid: this.$store.state.user._id })
          .then(response => {
            this.listeAssureurs = response.data.partenaires
            console.log(this.listeAssureurs)
          }).catch(err => { })
      } else if (this.$store.state.user.role == 'agent général') {
        Api().get('/partenaire/' + this.$store.state.user.societe)
          .then(response => {
            this.listeAssureurs = response.data.partenaire
          })
      } else if (this.$store.state.user.role == 'Admin' || this.$store.state.user.role == "SuperAdmin") {
        Api().get('/partenaire')
          .then(response => {
            this.listeAssureurs = response.data.partenaires //.assureurs_partenaires
          })
      } else if (this.$store.state.user.role == 'Assureur') {
        Api().get('/partenaire/' + this.$store.state.user.societe)
          .then(response => {
            this.listeAssureurs = response.data.partenaire
          })
      }
    },
    dateFormated(theDate) {
      if (theDate) {
        return dayjs(theDate).format('YYYY-MM-DD')
      }
    },

    createuser: function () {
      this.datenaissance = this.dateFormated(this.datenaissance)

      if (this.nom &&this.prenom && this.genre && this.telephone_port && this.email && this.userRole) 
      {
        this.generatePassword()
        if (this.$store.state.user.role == "SuperAdmin" || this.$store.state.user.role == "Admin") {
          let user = {
            nom: this.nom,
            prenom: this.prenom,
            datenaissance: this.datenaissance,
            profession: this.profession,
            adresse: this.adresse,
            sexe: this.genre,
            telephone_port: this.telephone_port,
            telephone_bur: this.telephone_bur,
            email: this.email,
            password: this.password1,
            confirmPass: this.password1,
            role: this.userRole,
            createdby: this.$store.state.user._id,
            photo: this.file,
            piece_identite: this.type_piece,
            numero_piece: this.numero_piece,
            statut_marital: this.statut_marial,
            nb_enfant: this.nb_enfant,
            plateforme: 'Dash',
            agent_admin: this.agent_admin,
            created: dayjs(new Date()).format('YYYY-MM-DD')
          }

          if (this.choosenRole.name.toUpperCase() == 'AGENT' || this.choosenRole.name.toUpperCase() == 'ASSUREUR') {
            if (this.societeAdmin == null){
              this.$message.success('le partenaire est obligatoire !')
              return ;
            }
            user.societe = this.societeAdmin
          }

          Api().post('user/add', user)
            .then(resultat => {
              if (resultat.status == 200) {
                if (resultat.data.numero) {
                  this.$message.error(resultat.data.msg, this.AlertMessage)
                } else {
                  if (this.choosenRole != null && this.choosenRole.name.toUpperCase() == "AGENT") {

                    this.generateBrancheAssurAgent(resultat.data.data._id)
                    this.ClearForm();
                    this.societeAdmin = null;
                    this.$message.success('Nouveau utilisateur ajouté !', this.AlertMessage)
                  } else {
                    console.log("resultat.data ")
                    console.log(resultat.data)
                    this.ClearForm();
                    this.societeAdmin = null;
                    this.$message.success('Nouveau utilisateur ajouté !', this.AlertMessage)
                  }
                }

              } else {
                this.$message.error('Ajout de user échoué !', this.AlertMessage)
              }
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.$message.error("Vous n'avez pas le droit requis pour cet action !", this.AlertMessage)
        }
      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }
    },

    // Generation d'un password temporaire pour le user  
    generatePassword: function () {
      this.password1 = customPassword.customPassword(this.nom, this.prenom)
    },

    HubspotSend(param) {
      let data = {
        "email": param.email,
        "firstname": param.prenom,
        "lastname": param.nom,
        "phone": param.telephone_port,
        "inscrit": "Dash"
      };
      Api().post('/testimonial/hubspot', data)
        .then(resp => {
          (resp.data)
        })
        .catch(e => {
          (e)
        })
    },

    MailChimpInscriptionCompte(param) {
      let data = {
        "email": param.email,
        "FNAME": param.prenom,
        "LNAME": param.nom,
        "PHONE": param.telephone_port,
      };
      Api().post('/testimonial/mailchimp', data)
        .then(resp => {
          (resp.data)
        })
        .catch(e => {
          (e)
        })
    },

    setBcryptHash() {
      const self = this;
      self.calculating = true;
      bcrypt.genSalt(self.saltLength, (err, salt) => {
        if (!err) {
          bcrypt.hash(self.password, salt, (err, hash) => {
            if (!err) {
              self.result = `${hash}`;
              self.calculating = false;
            }
          });
        }
      });
    },

    ClearForm() {
      this.nom = ''
      this.prenom = ''
      this.datenaissance = ''
      this.profession = ''
      this.adresse = ''
      this.genre = ''
      this.telephone_port = ''
      this.telephone_bur = ''
      this.email = ''
      this.type_piece = ''
      this.numero_piece = ''
      this.statut_marital = ''
      this.nb_enfant = ''
      this.societe = ''
      this.agent_admin = false
    },

    getAllbranche() {
      Api().get("/branche/")
        .then((result) => {
          this.branchelist = result.data
        }).catch((err) => {
          console.log(err)
        });
    },

    __resizeHanlder: _.throttle(function (e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),
    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  created() {
    this.init()
    this.getUserRoles()
  },

  computed: {
    sortOptions() {
      return this.listuser
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }

          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }

          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }

          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
