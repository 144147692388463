<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
        <div class="page-header">
            <h1>Liste de tous les <span style="color:#ffa409">devis cloud archivés</span>
            </h1>
        </div>

        <div class="toolbar-box flex align-center" v-if="!isMobile">
            <div class="box grow">
                <el-input
                        placeholder="Rechercher devis archivé..."
                        prefix-icon="el-icon-search"
                        v-model="search"
                        clearable
                >
                </el-input>
            </div>
            <div class="box grow">
                <!--        A ne pas supprimer-->
            </div>
        </div>

        <div v-if="isMobile" style="margin-bottom:15px">
            <el-row :gutter="15">
                <el-col :span="24" style="margin-top:15px">
                    <el-input
                            placeholder="Rechercher devis archivé..."
                            prefix-icon="el-icon-search"
                            v-model="search"
                            clearable>
                    </el-input>
                </el-col>
            </el-row>
        </div>

        <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="!isMobile">
            <div class="box grow">
                <h3>Recherche par période de création</h3>
                <el-date-picker
                        v-model="du"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-date-picker
                        style="margin-left:15px"
                        v-model="au"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
            </div>
        </div>
        <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="isMobile">
            <div class="box grow">
                <h3>Recherche par période de création</h3>
                <el-date-picker
                        v-model="du"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-date-picker
                        style="margin-top:15px"
                        v-model="au"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
            </div>
        </div>
        
          <div class="mb-5" v-if="this.$store.state.user.role == 'SuperAdmin'||this.$store.state.user.role == 'Admin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
            <el-table
                    :data="listInPage"
                    style="width: 100%"
                    :height="height"
                    v-if="ready"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column fixed label="Date création" min-width="150" prop="dateSouscription">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="200" prop="partner">
                  <template slot-scope ="scope">
                        <span class="date">
                        <a href="#">
                            {{getPartner(scope.row)}}
                        </a>
                        </span>
                  </template>
              </el-table-column>
                <el-table-column label="Client" min-width="200" prop="client">
                    <template slot-scope ="scope">
                <span class="date">
                  <a href="#" @click="SetDetailUser(scope.row.userid)">
                    {{scope.row.userid.prenom }} - {{scope.row.userid.nom }}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Num devis" min-width="180" prop="numeroDevis"></el-table-column>
                <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
                <el-table-column label="Code Partenaire" min-width="150" prop="codep">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Plateforme" min-width="150" prop="plateforme">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.plateforme }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
                    <template slot-scope ="scope">
                        <span>{{scope.row.prime_ttc.toLocaleString()}} CFA</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Créateur" min-width="150" prop="creator">
                    <template slot-scope ="scope" v-if="$store.state.user.role=='Admin' ||  $store.state.user.role=='Agent'">
                        <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
                        <span v-else>
              Admin
            </span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
                        <el-button size="mini" @click="DesarchivateDevis(scope.row)" type="primary" icon="el-icon-refresh-left" circle title="Désarchiver"></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    v-if="ready"
                    :small="pagination.small"
                    :current-page.sync="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size.sync="pagination.size"
                    :layout="pagination.layout"
                    :total="total"
            ></el-pagination>
        </div>

        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
            <el-table
                    :data="listInPage"
                    style="width: 100%"
                    v-if="ready"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column label="Date création" min-width="150" prop="dateSouscription">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Client" min-width="200" prop="client">
                    <template slot-scope ="scope">
                <span class="date">
                  <a href="#" @click="SetDetailUser(scope.row.userid)">
                    {{scope.row.userid.prenom }} - {{scope.row.userid.nom }}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Num devis" min-width="180" prop="numeroDevis"></el-table-column>
                <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
                <el-table-column label="Code Partenaire" min-width="150" prop="codep">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Plateforme" min-width="150" prop="plateforme">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.plateforme }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
                    <template slot-scope ="scope">
                        <span>{{scope.row.prime_ttc.toLocaleString()}} CFA</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Créateur" min-width="150" prop="creator">
                    <template slot-scope ="scope" v-if="$store.state.user.role=='Admin' ||  $store.state.user.role=='Agent'">
                        <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
                        <span v-else>
              Admin
            </span>
                    </template>
                </el-table-column>
                <el-table-column label="Actions" prop="action" min-width="120">
                    <template slot-scope="scope">
                        <el-button @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
                        <el-button @click="DesarchivateDevis(scope.row)" type="primary" icon="el-icon-refresh-left" circle title="Désarchiver"></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    v-if="ready"
                    :small="pagination.small"
                    :current-page.sync="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size.sync="pagination.size"
                    :layout="pagination.layout"
                    :total="total"
            ></el-pagination>
        </div>

        <el-dialog width="70%!important" title="Détails Devis" :visible.sync="showdevis" v-if="showdevis==true && !isMobile">
            <el-row :gutter="15">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateSouscription)}}</span></li>
                                    <li>Numéro devis : <span class="to-right assuraf-orange">{{detailsDevis.numeroDevis}}</span></li>
                                    <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEffet)}}</span></li>
                                    <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEcheance)}}</span></li>
                                    <li>Prime nette : <span class="to-right assuraf-orange">{{detailsDevis.prime_nette}} CFA</span></li>
                                    <li>Prime ttc : <span class="to-right assuraf-orange">{{detailsDevis.prime_ttc}} CFA</span></li>
                                    <li>Taxe : <span class="to-right assuraf-orange">{{detailsDevis.taxe}} CFA</span></li>
                                    <li>Accessoires : <span class="to-right assuraf-orange">{{detailsDevis.accessoires}} CFA</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Date d'archivage : <span class="to-right assuraf-orange">{{DateFormater(ArchivateDetails.archive_date)}}</span></li>
                                    <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEffet)}}</span></li>
                                    <li>
                                        <label>Raison de l'archivage</label>
                                        <el-input type="textarea" :rows="2" placeholder="Pourquoi supprimer?" v-model="ArchivateDetails.archive_reason" readonly>
                                        </el-input>
                                    </li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="90%!important" title="Détails Devis" :visible.sync="showdevis" v-if="showdevis==true && isMobile">
            <el-row :gutter="15">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateSouscription)}}</span></li>
                                    <li>Numéro devis : <span class="to-right assuraf-orange">{{detailsDevis.numeroDevis}}</span></li>
                                    <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEffet)}}</span></li>
                                    <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEcheance)}}</span></li>
                                    <li>Prime nette : <span class="to-right assuraf-orange">{{detailsDevis.prime_nette}} CFA</span></li>
                                    <li>Prime ttc : <span class="to-right assuraf-orange">{{detailsDevis.prime_ttc}} CFA</span></li>
                                    <li>Taxe : <span class="to-right assuraf-orange">{{detailsDevis.taxe}} CFA</span></li>
                                    <li>Accessoires : <span class="to-right assuraf-orange">{{detailsDevis.accessoires}} CFA</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Date d'archivage : <span class="to-right assuraf-orange">{{DateFormater(ArchivateDetails.archive_date)}}</span></li>
                                    <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateEffet)}}</span></li>
                                    <li>
                                        <label>Raison de l'archivage</label>
                                        <el-input type="textarea" :rows="2" placeholder="Pourquoi supprimer?" v-model="ArchivateDetails.archive_reason" readonly>
                                        </el-input>
                                    </li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="70%" title="Client" :visible.sync="showUser" v-if="showUser==true && !isMobile">
            <el-row :gutter="15">
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser==true && isMobile">
            <el-row :gutter="15">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>


    import _ from "lodash"
    import moment from "moment-timezone"

    //debut importation assuraf
    import dayjs from 'dayjs'
    import Api from '../../../services/Api'
    import store from '../../../store'
    //fin importation assuraf

    export default {
        name: "StyledTablePage",
        data() {
            return {
                nomPartenaire : '',
                partnerName : [],
                showUser: false,
                detailsUser : [],
                ArchivateDetails:null,


                Delete_reason: '',
                /*=============*/
                up_primenette:null,
                up_accessoires:null,
                up_taxes:null,
                up_primettc:null,
                money: {
                    thousands: ' ',
                    precision: false,
                    masked: false
                },
                UpdatePrimeDevis:false,
                items: [],

                totalItems: null,
                isBusy: false,
                optionsdateModif: {
                    useCurrent: false,
                    format: 'YYYY/MM/DD',
                },
                dateEffet: null,
                dateEcheance: null,
                duree: null,
                CalculDuree:null,
                JsonView:false,
                specialUpdatedevis: false,
                modalgenerationloading : false,
                optionsdate: {
                    format: 'YYYY/MM/DD',
                    useCurrent: false,
                },
                ldevis: [],
                du: null,
                au: null,
                listdevis: [],
                modalgeneration: false,
                detailsDevis: [],
                showdevis: false,
                pageOptions: [5, 10, 15],
                modalInfo: {
                    title: '',
                    content: ''
                },
                MenuHeader:[
                    {key: "theme",label: "Libéllé"},
                    {key: "collapse",label: "Collapse"},
                    {key: "sousmenu",label: "Sous menu"},
                    {key: "action", label:"action"}
                ],
                userflette:[],
                fletteRows:null,
                editUser : false,
                Selectedbranche:null,
                selectedAssureur:[],
                configUserDevisAssureur:null,
                assureurs:[],
                branchelist:null,
                modalSociete: false,
                configHead:[
                    { key:"branche", label:"branche"},
                    { key:"assureurs", label:"assureur"},
                    { key:"action", label:"Action"}
                ],
                selectedUser:null,
                listeAssureurs: null,
                usermenu:[],
                collapse:null,
                menu_name:null,
                showConfigMenu:false,
                submenu:null,
                userSubmenu:[],
                UserFinalMenu:[],

                username: null,
                data: {},
                html: '',
                Mycounter: 0,
                dismissSecs: 3,
                totalRows: null,
                perPage: 8,
                UpdateOk: false,
                deleteConfirmation: false,
                modalDelete: false,
                dismissCountDown: 0,
                detailsUser: [],
                listuser: null,
                filter: null,
                showuser: false,
                voidField: false,
                isMobile: false,
                ready: false,
                width: 0,
                height: "auto",
                loading: false,
                search: "",
                pagination: {
                    page: 1,
                    size: 100,
                    sizes: [10, 15, 20, 30, 50, 100],
                    layout: "total, ->, prev, pager, next, jumper, sizes",
                    small: false
                },
                list: this.listdevis, //users,
                editMode: false,
                itemsChecked: [],
                dialogUserVisible: false,
                currentId: 0,
                moment
            }
        },
        computed: {
            listFiltered() {
                if(this.search.length >= 4){
                    return this.listdevis.filter(obj => {
                        for (let k in obj)
                        {
                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                ctrl = true
                                /*on le push dans le tableau */
                                //this.listdevis.push(obj)
                            }else{
                                /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
                                if(Array.isArray(obj[k])){
                                    obj[k].filter(nobj=>{
                                        for(let l in nobj){
                                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                            if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                                ctrl = true
                                                /* on le push dans le tableau */
                                                //this.listdevis.push(obj)
                                            }
                                        }
                                    })
                                    /* Si la valeur courante n'est ni String, ni Tableau mais Object */
                                }else if(!Array.isArray(obj[k]) && typeof obj[k]=="object" && obj[k]!=null){
                                    /*== On crée un nouveau tablequ vide ==*/
                                    let my_new_array = []

                                    /*== On push la valeur courante dans le nouveau tableau ==*/
                                    my_new_array.push(obj[k])

                                    /*On refiltre le nouveau tableau atrribut par attribut*/
                                    my_new_array.filter(nobj=>{
                                        for(let l in nobj){
                                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                            if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                                ctrl = true

                                                /* on le push dans le tableau */
                                                //this.listdevis.push(obj)
                                            }
                                        }
                                    })
                                }
                            }
                        }
                        return ctrl
                    })
                }else{
                    return this.listdevis.filter(obj => {
                        let ctrl = false
                        for (let k in obj)
                        {
                            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
                                ctrl = true
                        }
                        return ctrl
                    })
                }
            },
            listSortered() {
                let prop = this.sortingProp
                let order = this.sortingOrder
                return [].concat(
                    this.listFiltered.sort((item1, item2) => {
                        let val1 = ""
                        let val2 = ""

                        val1 = item1[prop]
                        val2 = item2[prop]
                        if (order === "descending") {
                            return val2 < val1 ? -1 : 1
                        }
                        return val1 < val2 ? -1 : 1
                    })
                )
            },
            listInPage() {
                let from = (this.currentPage - 1) * this.itemPerPage
                let to = from + this.itemPerPage * 1
                return this.listFiltered.slice(from, to)
            },
            total() {
                return this.listFiltered.length
            },
            currentPage: {
                get() {
                    return this.pagination.page
                },
                set(val) {
                    this.pagination.page = val
                }
            },
            itemPerPage() {
                return this.pagination.size
            },
            selectedItems() {
                return this.itemsChecked.length || 0
            }
        },
        watch: {
            isBusy(newVal, oldVal) {
                if (newVal !== oldVal) {
                    const tableScrollBody = this.$refs["my-table"].$el;
                    if (newVal === true) {
                        tableScrollBody.classList.add("overflow-hidden");
                    } else {
                        tableScrollBody.classList.remove("overflow-hidden");
                    }
                }
            },

            itemPerPage(val) {
                this.ready = false
                this.currentPage = 1

                setTimeout(() => {
                    this.ready = true
                }, 500)
            },
            search(val) {
                this.currentPage = 1
            }
        },
        methods: {
            exportToExcel(){
                Api().get('/export/devis/archive/systeme')
                    .then(resultat => {
                        this.modalgenerationloading = true;
                        let url = resultat.data.lien
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                                window.open(url)), 7000
                        );
                    });
            },
            getPartner(item)
            {
                let partner = ""
                for(let p of item.userid.affiliated_partner)
                {
                   if(p.code_partenaire==item.codep){
                    partner = p.namePartner
                   }
                }
                return partner
            },
            SetDetailUser(details) {
                this.detailsUser = details
                this.showUser = true
            },
            detaildevis(item) {
                this.getDevisArchivateDetails(item._id)
                this.detailsDevis = item
                this.showdevis = true
            },
            getDevis() {

                if (this.$store.state.user.role == 'agent général') {
                    Api().get('/devis/assureur/archive/' + this.$store.state.user.societe)
                        .then(resultat => {
                            this.listdevis = resultat.data.devis
                            this.totalRows = resultat.data.devis.length
                        });

                } else if (this.$store.state.user.role == 'Agent') {
                    Api().get('/devis/courtier/archive/' + this.$store.state.user.societe)
                        .then(resultat => {
                            this.listdevis = resultat.data.devis
                            this.totalRows = resultat.data.devis.length
                        });
                } else if (this.$store.state.user.role == 'Assureur') {
                    Api().get('/devis/assureur/archive/' + this.$store.state.user.societe)
                        .then(resultat => {
                            this.listdevis = resultat.data.devis
                        });
                } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin') {
                    Api().get('/system/devis/archives/')
                        .then(resultat => {
                            this.listdevis = resultat.data.devis
                            this.totalRows = resultat.data.devis.length
                            //this.getPartner()
                        });
                }

            },

            DesarchivateDevis(devis) {
                let id = devis._id
                Api().post('/system/devis/desarchivate/' + id)
                    .then(resp => {
                        this.$message.success('Devis desarchivé avec succés !', this.AlertMessage)
                        this.getDevis()
                    })
            },

            getDevisArchivateDetails(id)
            {
                //ArchivateDetails
                Api().get('/system/devis/archivate/detail/'+id)
                    .then(resultat => {
                        this.ArchivateDetails = resultat.data.detail
                    })
                    .catch(err => {})

            },


            AlertMessage() {
                this.$message({
                    showClose: false,
                    duration : 5000
                });
            },

            SpecialUpdatePrime()
            {
                if(this.up_primenette!=null && this.up_primenette!=0 && this.up_accessoires!=null && this.up_accessoires!=0 && this.up_taxes!=null && this.up_taxes!=0)
                {
                    let pNette          = Number(this.up_primenette.replace(/[^0-9.-]+/g, ""))
                    let access          = Number(this.up_accessoires.replace(/[^0-9.-]+/g, ""))
                    let taxe            = Number(this.up_taxes.replace(/[^0-9.-]+/g, ""))
                    this.up_primettc    = pNette + access + taxe

                    const data = {
                        prime_nette:pNette,
                        accessoires:access,
                        taxe:taxe,
                        prime_ttc:this.up_primettc,
                        devisid:this.detailsDevis._id
                    }
                    Api().post("/system/devis/update/primes", data).then((result) => {
                        if(result.data.devis.nModified==1){
                            this.$message.success('Opération effectuée !', this.AlertMessage)
                            this.UpdatePrimeDevis = false
                            this.showdevis        = false
                            this.getDevis()
                        }else{
                            this.$message.error('Opération échouée !', this.AlertMessage)
                        }
                    }).catch((err) => {

                    });

                }else{
                    this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
                }
            },

            primeNetteCalculation()
            {
                if(this.up_primenette!=null && this.up_primenette!=0 && this.up_accessoires!=null && this.up_accessoires!=0 && this.up_taxes!=null && this.up_taxes!=0)
                {
                    let pNette      = Number(this.up_primenette.replace(/[^0-9.-]+/g, ""))
                    let access      = Number(this.up_accessoires.replace(/[^0-9.-]+/g, ""))
                    let taxe        = Number(this.up_taxes.replace(/[^0-9.-]+/g, ""))
                    this.up_primettc   = pNette + access + taxe
                }

            },

            JsonFormaterView()
            {
                if(this.JsonView==false)
                {
                    this.JsonView = true
                }else{
                    this.JsonView = false
                }
            },

            CheckIfTypeDefined(item){
                return typeof item
            },

            // exportToExcel(){
            //     Api().get('/system/export/devis')
            //         .then(resultat => {
            //             this.modalgeneration = true
            //             let url = resultat.data.lien
            //             setTimeout(() => (
            //                 this.modalgeneration = false,
            //                     window.open(url)), 7000
            //             );
            //         });
            // },

            dateDiff: function () {
                if (this.dateEffet && this.duree && this.CalculDuree)
                {
                    let inter = dayjs(this.dateEffet).add(this.duree, this.CalculDuree).format('YYYY-MM-DD');
                    this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
                }
            },

            /*================*/
            SpecialUpdate() {
                if (this.duree && this.dateEffet && this.dateEcheance && this.numeroDevis) {

                    let data = {
                        devisid: this.detailsDevis._id,
                        duree: this.duree,
                        dateEffet: dayjs(this.dateEffet).format('YYYY-MM-DD'),
                        dateEcheance: dayjs(this.dateEcheance).format('YYYY-MM-DD'),
                        numeroDevis: this.numeroDevis
                    }

                    Api().post('/system/devis/update/special', data)
                        .then((result) => {
                            if (result.data.msg = "Devis modifié !") {
                                this.specialUpdatedevis = false
                            }
                        }).catch((err) => {
                        console.log('Nooooo !')
                    });

                } else {
                    this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
                }

            },

            // Fonction de download du devis en fichier pdf
            Downloa_devis: function (devis) {
                Api().get('devis/download/' + devis._id)
                    .then(response => {
                        this.modalgenerationloading = true;
                        let url = response.data.lien
                        let WinOpener = null
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                                WinOpener = window.open(),
                                WinOpener.location = url
                        ), 7000);
                    })
            },

            Edit_devis: function (devis) {

                switch (devis.brancheid.branche) {
                    case 'Automobile':
                        Api().get('devis/' + devis._id)
                            .then(response => {
                                this.$store.dispatch('setDevisReopen', response.data.devis)
                                this.$router.go(this.$router.push({
                                    name: 'update_devis_auto'
                                }))
                            })

                        break;

                    case 'Habitation':
                        this.$router.go(this.$router.push({
                            name: 'update_devis_habitation'
                        }))
                        break;

                    case 'Voyage':
                        this.$router.go(this.$router.push({
                            name: 'update_devis_voyage'
                        }))
                        break;

                }
            },


            Remover() {
                Api().post('sinistre/remove')
                    .then(rep => {})
            },


            devisDeleteConfirmation(item) {
                this.modalDelete = true
                this.detailsDevis = item
            },

            ArchivateDevis(devis) {
                let id = devis._id
                Api().post('devis/archivate/' + id)
                    .then(resp => {
                        this.getDevis()
                    })
            },

            AddEtatUser() {
                Api().post('devis/addetatuser')
                    .then(resultat => {})
                    .catch(err => {})
            },

            DeletingDevis(devis)
            {
                if(this.Delete_reason)
                {
                    const Adevis = {
                        userid: store.state.user._id,
                        brancheid: devis.brancheid._id,
                        devisid: devis._id,
                        archive_reason: this.Delete_reason
                    }
                    Api().post('devis/delete', Adevis)
                        .then(resultat => {
                            if (resultat.data.rep == 1)
                            {
                                this.Delete_reason = ""
                                this.modalDelete = false
                                this.getDevis()
                                this.$message.success('Devis archivé avec succés !', this.AlertMessage)

                            }
                        })

                }else{
                    this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
                }

            },

            FiltreDate() {
                if (this.du && this.au) {
                    switch (store.state.user.role) {
                        case 'Admin':
                            Api().post('devis/filtre/periode', {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listdevis = resultat.data.devis
                                });
                            break;

                        case 'Agent':
                            Api().post('devis/filtre/courtier/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listdevis = resultat.data.devis
                                });
                            break;

                        case 'assureur':
                            Api().post('devis/filtre/assureur/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listdevis = resultat.data.devis
                                });
                            break;
                        case 'agent général':
                            Api().post('devis/filtre/assureur/periode/' + store.state.user.assureur, {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listdevis = resultat.data.devis
                                });
                            break;

                        default:
                            break;
                    }
                } else {
                    this.$message.error('Les deux dates sont obligatoire !', this.AlertMessage)
                }

            },







            toggleDetails(item){
                this.detailsUser = item
                this.editUser = true
            },







            DeleteBrancheCourtier(item) {
                Api().post("/branchecourtier/delete", {id:item._id})
                    .then((result) => {
                        Api().post("/branchecourtier/get/all/", {userid:item.userid})
                            .then((response) => {
                                this.configUserDevisAssureur = response.data.partenaires
                            })
                            .catch((err) => {});
                    }).catch((err) => {

                });
            },

            AddbrancheConfig() {
                Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
                    .then((result) => {
                        this.configUserDevisAssureur = []
                    }).catch((err) => {
                    console.log(err)
                });
            },

            pushConfig() {
                if(this.Selectedbranche && this.selectedAssureur)
                {
                    this.configUserDevisAssureur.push({
                        branche:this.Selectedbranche,
                        assureurs:this.selectedAssureur,
                        userid: this.selectedUser.id
                    })
                    this.Selectedbranche  = null
                    this.selectedAssureur = []
                }
            },

            loadConfigBranchePopUp(item) {
                this.modalSociete = true
                this.selectedUser = item
                Api().post("/branchecourtier/get/all/", {userid:item.id})
                    .then((response) => {
                        this.configUserDevisAssureur = response.data.partenaires
                    })
                    .catch((err) => {});
            },

            setCollapse(){
                this.collapse = "id_"+Date.now()+"_collapse"
            },

            MenuDelete(item)
            {
                Api().delete("/menu/"+item._id)
                    .then((result) => {
                        this.getUserMenu(this.detailsUser._id)
                    }).catch((err) => {

                });
            },

            pushMenu()
            {
                if(this.menu_name && this.userSubmenu.length >= 1)
                {
                    this.UserFinalMenu.push({
                        user : this.detailsUser.id,
                        theme:this.menu_name,
                        collapse:this.collapse,
                        config:this.userSubmenu
                    })
                    this.menu_name   = null
                    this.userSubmenu = []
                    this.collapse    = null
                }else{
                    this.$message.error('Remplissez les champs vides !', this.AlertMessage)
                }
            },

            create_user_menu()
            {
                if(this.UserFinalMenu.length >=1 )
                {
                    Api().post("/menu/add", this.UserFinalMenu)
                        .then((result) => {
                            this.$message.error('Opération éffectuée !', this.AlertMessage)
                            console.log(result.data)
                            this.UserFinalMenu = []
                        }).catch((err) => {
                        console.log(err)
                    });
                }else{
                    this.$message.error('Le menu ne doit pas être vide !', this.AlertMessage)
                }
            },

            merge(){
                Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
                    .then((result) => {
                        this.showuser = false
                        if(result.data.result){
                            alert('Opération réussie')
                        }
                    }).catch((err) => {
                    console.log('=======err=========')
                });
            },

            detailuser(item) {
                this.getUserMenu(item._id)
                this.detailsUser = item
                this.showuser = true
            },

            ready: function () {
                var self = this;

                setInterval(function () {
                    self.$data.ticker = Date.now();
                }, 1000);
            },

            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },

            showAlert() {
                this.dismissCountDown = this.dismissSecs
            },

            getUserList:function(){
                switch (this.$store.state.user.role) {
                    case 'Admin':
                        Api().get('/user/frontoffice/')
                            .then( resultat => {
                                this.listuser = resultat.data
                                this.totalRows = resultat.data.length
                            });
                        break;


                    case 'assureur':
                        Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
                            .then( resultat => {
                                this.listuser = resultat.data
                                this.totalRows = resultat.data.length
                            });
                        break;

                    default:
                        break;
                }
            },

            UpdateUser(item) {

                let idU = item.id
                Api().post('user/' + idU, item)
                    .then(response => {
                        if (response.data) {
                            this.showAlert()
                        }
                        this.showAlert()
                    })
                    .catch(err => {

                    })

            },

            DateFormater: function (TheDate) {
                if (TheDate) {
                    return dayjs(TheDate).format('DD-MM-YYYY')
                }
            },

            userDeleteConfirmation(item) {

                this.modalDelete = true
                this.detailsUser = item

            },

            DeletingUser(id) {
                Api().delete('user/delete/' + id)
                    .then(resultat => {
                        if (resultat.status == 200) {
                            this.modalDelete = false
                            this.getUserList()
                        }
                    })
                    .catch(err => {})
            },

            getUserMenu(id){
                Api().post('/menu/user/menu', {userid:id})
                    .then((result) => {
                        this.usermenu = result.data.menus
                        console.log(result.data.menus)
                    }).catch((err) => {
                    console.log(err)
                });
            },








            calcDims() {
                const tableWrapper = document.getElementById("table-wrapper")
                if (tableWrapper) this.width = tableWrapper.clientWidth

                if (!this.isMobile && tableWrapper) {
                    this.height = tableWrapper.clientHeight - 44
                }

                if (this.width < 480) {
                    this.pagination.small = true
                    this.pagination.layout = "prev, pager, next"
                } else if (this.width >= 480 && this.width < 700) {
                    this.pagination.small = false
                    this.pagination.layout = "prev, pager, next, ->, sizes"
                } else {
                    this.pagination.small = false
                    this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
                }

                this.ready = true
            },
            handleResize: _.throttle(function(e) {
                this.ready = false
                this.width = 0
                setTimeout(this.calcDims, 1000)
            }, 500),
            handleSelectionChange(val) {
                this.itemsChecked = val
            },
            init() {
                if (window.innerWidth <= 768) this.isMobile = true
            }
        },
        filters: {
            selected: function(value, sel) {
                if (!value) return ""
                if (!sel) return value

                value = value.toString()
                sel = sel.toString()

                const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
                if (startIndex !== -1) {
                    const endLength = sel.length
                    const matchingString = value.substr(startIndex, endLength)
                    return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
                }

                return value
            }
        },
        created() {
            this.init()
            this.getDevis()
        },
        mounted() {
            if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
                this.getDevis()

            } else {
                this.$router.go(this.$router.push('/login'))
            }

            //ie fix
            if (!window.Number.parseInt) window.Number.parseInt = parseInt

            this.calcDims()
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/_variables";

    .page-table {
        &.overflow {
            overflow: auto;
        }

        .toolbar-box {
            &.hidden {
                visibility: hidden;
            }
        }

        .table-box {
            overflow: hidden;

            &.hidden {
                visibility: hidden;
            }
        }
    }
</style>

<style lang="scss">
    @import "../../../assets/scss/_variables";

    .page-table {
        .toolbar-box {
            margin-bottom: 10px;

            .card-shadow--medium {
                &.el-input,
                &.el-select {
                    border-radius: 4px;

                    input {
                        border-color: transparent;
                        background-color: lighten($background-color, 2%);
                        color: $text-color;

                        &:hover {
                            border-color: #c0c4cc;
                        }
                        &:focus {
                            border-color: $text-color-accent;
                        }
                    }
                }

                &.el-button {
                    border-color: transparent;
                }
            }
        }

        .clickable {
            cursor: pointer;
            text-decoration: underline;
            font-weight: bold;
        }

        .sel-string {
            .sel {
                background: transparentize($text-color, 0.8);
                border-radius: 5px;
                //text-transform: uppercase;
            }
        }
    }

    @media (max-width: 768px) {
        .page-table {
            .toolbar-box {
                display: block;
                overflow: hidden;
                font-size: 80%;
                padding-bottom: 10px;

                & > * {
                    display: inline-block;
                    min-width: 120px;
                    height: 22px;
                    //background: rgba(0, 0, 0, 0.04);
                    margin-bottom: 16px;
                }
            }
        }
    }
    .button-filtre{
        padding: 12px!important;
        margin : 0 15px!important;
        background: #C0C7CF!important;
        border:none!important;
        color:#fff!important;
    }
</style>
