<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
      <div class="page-header">
        <h1>Liste des demandes d'<span style="color:#ffa409">assistance</span>
          <theme-picker style="float:right"></theme-picker>
        </h1>
      </div>
  
       <div class="toolbar-box flex align-center" v-if="!isMobile">
        <div class="box grow">
          <el-input
              placeholder="Rechercher assistance..."
              prefix-icon="el-icon-search"
              v-model="search"
              clearable
          >
          </el-input>
        </div>
        <div class="box grow">
          <!--        A ne pas supprimer-->
        </div>
      </div>
  
      <div v-if="isMobile" style="margin-bottom:15px">
        <el-row :gutter="15">
          <el-col :span="24" style="margin-top:15px">
            <el-input
              placeholder="Rechercher assistance..."
              prefix-icon="el-icon-search"
              v-model="search"
              clearable>
          </el-input>
          </el-col>
        </el-row>
      </div>
  
      <resize-observer @notify="handleResize" />

        <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>
  
      <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
        <el-table
            :data="listInPage"
            style="width: 100%"
            :height="height"
            :row-class-name="tableRowClassName"
            v-if="ready"
            @selection-change="handleSelectionChange"
        >
          <el-table-column label="Date création" min-width="150" fixed prop="created">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Horaire de rappel" min-width="150" prop="horaire"></el-table-column>
          <el-table-column label="Canal" min-width="180" prop="canal"></el-table-column>
          <el-table-column label="Client" min-width="200" prop="nomprenom"></el-table-column>
          <el-table-column label="Téléphone" min-width="150" prop="telephone"></el-table-column>
          <el-table-column label="Objet" min-width="200" prop="objet_assistance"></el-table-column>
          <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
            <template slot-scope="scope">
              <el-button size="mini" @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
              <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading" @click="AssistanceDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="total"
        ></el-pagination>
      </div>
  
       <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
        <el-table
            :data="listInPage"
            style="width: 100%"
            v-if="ready"
            :row-class-name="tableRowClassName"
            @selection-change="handleSelectionChange"
        >
          <el-table-column label="Date création" min-width="150" prop="created">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Horaire de rappel" min-width="150" prop="horaire"></el-table-column>
          <el-table-column label="Canal" min-width="180" prop="canal"></el-table-column>
          <el-table-column label="Client" min-width="200" prop="nomprenom"></el-table-column>
          <el-table-column label="Téléphone" min-width="150" prop="telephone"></el-table-column>
          <el-table-column label="Objet" min-width="200" prop="objet_assistance"></el-table-column>
          <el-table-column label="Actions" prop="action" min-width="100">
            <template slot-scope="scope">
              <el-button size="mini" @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
              <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading" @click="AssistanceDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="total"
        ></el-pagination>
      </div>
  
      <el-dialog width="70%" title="Détails assistance" :visible.sync="showdevis" v-if="showdevis && !isMobile">
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Nom : <span class="to-right assuraf-orange">{{detailAssistance.userid.nom}}</span></li>
                    <li>Prénom : <span class="to-right assuraf-orange">{{detailAssistance.userid.prenom}}</span></li>
                    <li>Profession : <span class="to-right assuraf-orange">{{detailAssistance.userid.profession}}</span></li>
                    <li>Date d'inscription : <span class="to-right assuraf-orange">{{DateFormater(detailAssistance.userid.created)}}</span></li>
                  </ul>
                </div>
                <div style="text-align: center!important;">
                  <img class="justify-center" :src="detailAssistance.userid.photo" width="100" alt="photo de profil">
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailAssistance.created)}}</span></li>
                  <li>Horaire de rappel : <span class="to-right assuraf-orange">{{detailAssistance.horaire}}</span></li>
                  <li>Canal : <span class="to-right assuraf-orange">{{detailAssistance.canal}}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{detailAssistance.telephone}}</span></li>
                </ul>
                <!--              <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsAssu.logo" width="180" alt="photo de profil">
                              </div>-->
              </el-card>
            </div>
          </el-col>
        </el-row>
  
        <el-row :gutter="15">
          <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div  >
                  <ul style="list-style:none">
                    <li>Contenu de la demande : <span class="to-right assuraf-orange">{{detailAssistance.remarques}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
  
       <el-dialog width="90%" title="Détails assistance" :visible.sync="showdevis" v-if="showdevis && isMobile">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Nom : <span class="to-right assuraf-orange">{{detailAssistance.userid.nom}}</span></li>
                    <li>Prénom : <span class="to-right assuraf-orange">{{detailAssistance.userid.prenom}}</span></li>
                    <li>Profession : <span class="to-right assuraf-orange">{{detailAssistance.userid.profession}}</span></li>
                    <li>Date d'inscription : <span class="to-right assuraf-orange">{{DateFormater(detailAssistance.userid.created)}}</span></li>
                  </ul>
                </div>
                <div style="text-align: center!important;">
                  <img class="justify-center" :src="detailAssistance.userid.photo" width="100" alt="photo de profil">
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailAssistance.created)}}</span></li>
                    <li>Horaire de rappel : <span class="to-right assuraf-orange">{{detailAssistance.horaire}}</span></li>
                    <li>Canal : <span class="to-right assuraf-orange">{{detailAssistance.canal}}</span></li>
                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailAssistance.telephone}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Contenu de la demande : <span class="to-right assuraf-orange">{{detailAssistance.remarques}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
  
      <el-dialog title="Suppression assistance" width="50%" :visible.sync="modalDelete" v-if="modalDelete==true">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Voulez-vous supprimer cette demande d'assistance?</span>
            </div>
            <div>
              <ul style="list-style:none">
                <li>De : <span class="to-right assuraf-orange">{{detailAssistance.nomprenom.toUpperCase()}}</span></li>
              </ul>
            </div>
          </el-card>
        </div>
        <div style="margin-top:15px">
          <el-button type="danger" round @click="DeletingAssistance(detailAssistance._id)">Oui</el-button>
          <el-button type="primary" round @click="modalDelete = false">Non</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  
  
  import _ from "lodash"
  import moment from "moment-timezone"
  
  //debut importation assuraf
  import dayjs from 'dayjs'
  import Api from '../../../services/Api'
  import store from '../../../store'
  //fin importation assuraf
  
  export default {
    name: "StyledTablePage",
    data() {
      return {
        listAssistance: [],
        detailAssistance: [],
        fields: [
          {key: 'created', label: 'Date creation'},
          {key: 'horaire', label: 'Horaire de rappel'},
          {key: 'canal',   label:  'Canal'},
          {key: 'nomprenom',label: 'Client'},
          {key: 'telephone', label: 'Téléphone'},
          {key: 'objet_assistance', label: 'Objet'},
          {key: 'action', label: 'Action'},
  
        ],
        modalgenerationloading : false,
        optionsdate: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
        },
        ldevis: [],
        du: null,
        au: null,
        listdevis: [],
        modalgeneration: false,
        detailsDevis: [],
        showdevis: false,
        pageOptions: [5, 10, 15],
  
        fieldsFiltres: [{
          key: 'dateSouscription',
          label: 'Date creation'
        },
          {
            key: 'brancheid.branche',
            label: 'Branche'
          },
          {
            key: 'numeroDevis',
            label: 'Num devis'
          },
          {
            key: 'partenaireid.societe',
            label: 'Assureur'
          },
          {
            key: 'courtier.societe',
            label: 'Courtier'
          },
          {
            key: 'dateEffet',
            label: 'Effet'
          },
          {
            key: 'dateEcheance',
            label: 'Echeance'
          },
          {
            key: 'prime_ttc',
            label: ' Prime TTC'
          },
          {
            key: 'creator',
            label: 'Createur'
          },
          {
            key: 'action',
            label: 'Action'
          },
  
        ],
        ToastTabSucess: {
          theme: "toasted-primary",
          position: "top-center",
          duration: 10000,
          fitToScreen: true,
          type: "success",
          className: "custom-success-class",
        }, // Customized options for success toast
        ToastTabError: {
          theme: "toasted-primary",
          position: "top-center",
          duration: 10000,
          fitToScreen: true,
          type: "error",
        }, // Customized options for error toast
        modalInfo: {
          title: '',
          content: ''
        },
        MenuHeader:[
          {key: "theme",label: "Libéllé"},
          {key: "collapse",label: "Collapse"},
          {key: "sousmenu",label: "Sous menu"},
          {key: "action", label:"action"}
        ],
        userflette:[],
        fletteRows:null,
        carFields:[
          {key:'marque',label:'Marque', sortable: true, sortDirection: 'desc'},
          {key:'model',label:'Model', sortable: true, sortDirection: 'desc'},
          {key:'immatriculation',label:'Immatriculation', sortable: true, sortDirection: 'desc'},
          {key:'carburation',label:'Carburation', sortable: true, sortDirection: 'desc'},
          {key:'added_date',label:"Date d'ajout"},
          {key:'action',label:'Action'},
        ],
        editUser : false,
        Selectedbranche:null,
        selectedAssureur:[],
        configUserDevisAssureur:null,
        assureurs:[],
        branchelist:null,
        modalSociete: false,
        configHead:[
          { key:"branche", label:"branche"},
          { key:"assureurs", label:"assureur"},
          { key:"action", label:"Action"}
        ],
        selectedUser:null,
        listeAssureurs: null,
        usermenu:[],
        collapse:null,
        menu_name:null,
        showConfigMenu:false,
        submenu:null,
        userSubmenu:[],
        UserFinalMenu:[],
        username: null,
        data: {},
        html: '',
        Mycounter: 0,
        dismissSecs: 3,
        totalRows: null,
        perPage: 8,
        UpdateOk: false,
        deleteConfirmation: false,
        modalDelete: false,
        dismissCountDown: 0,
        detailsUser: [],
        listuser: null,
        filter: null,
        showuser: false,
        voidField: false,
        isMobile: false,
        ready: false,
        width: 0,
        height: "auto",
        loading: false,
        search: "",
        pagination: {
          page: 1,
          size: 100,
          sizes: [10, 15, 20, 30, 50, 100],
          layout: "total, ->, prev, pager, next, jumper, sizes",
          small: false
        },
        list:  this.listAssistance ,//users,
        editMode: false,
        itemsChecked: [],
        dialogUserVisible: false,
        currentId: 0,
        moment
      }
    },
    computed: {
      listFiltered() {
        return this.listAssistance.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (
                obj[k] &&
                obj[k]
                    .toString()
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true
          }
          return ctrl
        })
      },
      /*listFiltered() {
        return this.list.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (
                obj[k] &&
                obj[k]
                    .toString()
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true
          }
          return ctrl
        })
      },*/
      listSortered() {
        let prop = this.sortingProp
        let order = this.sortingOrder
        return [].concat(
            this.listFiltered.sort((item1, item2) => {
              let val1 = ""
              let val2 = ""
  
              val1 = item1[prop]
              val2 = item2[prop]
              if (order === "descending") {
                return val2 < val1 ? -1 : 1
              }
              return val1 < val2 ? -1 : 1
            })
        )
      },
      listInPage() {
        let from = (this.currentPage - 1) * this.itemPerPage
        let to = from + this.itemPerPage * 1
        //return this.listSortered.slice(from, to)
        return this.listFiltered.slice(from, to)
      },
      total() {
        return this.listFiltered.length
      },
      currentPage: {
        get() {
          return this.pagination.page
        },
        set(val) {
          this.pagination.page = val
        }
      },
      itemPerPage() {
        return this.pagination.size
      },
      selectedItems() {
        return this.itemsChecked.length || 0
      }
    },
    watch: {
      itemPerPage(val) {
        this.ready = false
        this.currentPage = 1
  
        setTimeout(() => {
          this.ready = true
        }, 500)
      },
      search(val) {
        this.currentPage = 1
      }
    },
    methods: {
        exportToExcel(){
          //API de genereation donne en excel
        },
        tableRowClassName({row, rowIndex}) {
            if (!rowIndex) {
                console.log('okay')
            } else {
                return 'warning-row';
            }
            return '';
      },
      detaildevis(item) {
        this.detailAssistance = item
        this.showdevis = true
        Api().get('/assistances/update/'+item._id)
            .then(res=>{
              this.getAssistance()
            })
            .catch(e=>{
              console.log(e)
            })
      },
  
      rowClass(item, type) {
        if (!item) return
        if (item.vue === false) return 'table-danger'
      },
  
      getAssistance(){
        Api().get('/assistances/listing')
            .then(resultat => {
              this.listAssistance = resultat.data.assistance
              this.totalRows = resultat.data.assistance.length
            })
      },
  
  
  
  
  
  
  
      AssistanceDeleteConfirmation (item){
        this.modalDelete = true
        this.detailAssistance = item
      },
  
  
  
      Remover(){
  
        Api().post('/sinistre/remove')
            .then(resultat => {
  
            })
            .catch(err=>{
            })
      },
  
      DevisRemover(){
  
        Api().delete('/contrat/deleteall')
            .then(resultat => {
              alert(JSON.stringify(resultat.data))
            })
            .catch(err=>{
              alert(JSON.stringify(err))
            })
      },
  
      // Suppression de la demande d'assistance apres confirmation
      DeletingAssistance(id){
        Api().delete('/assistances/'+id)
            .then(resultat => {
              if (resultat.data.resp.n==1)
              {
                this.modalDelete = false
                this.getAssistance()
              }
            })
      },
  
  
  
  
      // Fonction de download du devis en fichier pdf
      Downloa_devis: function (devis) {
        Api().get('devis/download/' + devis._id)
            .then(response => {
              //this.modalgeneration = true
              this.modalgenerationloading = true;
              let url = response.data.lien
              setTimeout(() => (
                  this.modalgenerationloading = false,
                      window.open(url)), 7000);
            })
      },
  
      Edit_devis: function (devis) {
  
        switch (devis.brancheid.branche) {
          case 'Automobile':
            Api().get('devis/' + devis._id)
                .then(response => {
                  this.$store.dispatch('setDevisReopen', response.data.devis)
                  this.$router.go(this.$router.push({
                    name: 'update_devis_auto'
                  }))
                })
  
            break;
  
          case 'Habitation':
            this.$router.go(this.$router.push({
              name: 'update_devis_habitation'
            }))
            break;
  
          case 'Voyage':
            this.$router.go(this.$router.push({
              name: 'update_devis_voyage'
            }))
            break;
  
        }
      },
  
      getDevis() {
  
        if (this.$store.state.user.role == 'agent général') {
          Api().get('/devis/assureur/' + this.$store.state.user.societe)
              .then(resultat => {
                this.listdevis = resultat.data.devis
                this.totalRows = this.listdevis.length
              });
        } else if (this.$store.state.user.role == 'Agent') {
          Api().get('/devis/courtier/' + this.$store.state.user.societe)
              .then(resultat => {
                this.listdevis = resultat.data.devis
                this.totalRows = resultat.data.devis.length
              });
  
        } else if (this.$store.state.user.role == 'Assureur') {
          Api().get('/devis/assureur/' + this.$store.state.user.societe)
              .then(resultat => {
                this.listdevis = resultat.data.devis
                this.totalRows = resultat.data.devis.length
              });
        } else if (this.$store.state.user.role == 'Admin') {
          Api().get('/devis/')
              .then(resultat => {
                this.listdevis = resultat.data.devis
                this.totalRows = resultat.data.devis.length
              });
        }
  
      },
  
      devisDeleteConfirmation(item) {
        this.modalDelete = true
        this.detailsDevis = item
      },
  
      ArchivateDevis(devis) {
        let id = devis._id
        Api().post('devis/archivate/' + id)
            .then(resp => {
              this.getDevis()
            })
      },
  
      AddEtatUser() {
        Api().post('devis/addetatuser')
            .then(resultat => {})
            .catch(err => {})
      },
  
      DeletingDevis(id) {
        Api().delete('devis/delete/' + id)
            .then(resultat => {
              if (resultat.data.rep.n == 1) {
                this.modalDelete = false
                this.getDevis()
              }
            })
            .catch(err => {})
      },
  
      FiltreDate() {
        if (this.du && this.au) {
          switch (store.state.user.role) {
            case 'Admin':
              Api().post('devis/filtre/periode', {
                du: this.du,
                au: this.au
              })
                  .then(resultat => {
                    this.listdevis = resultat.data.devis
                  });
              break;
  
            case 'Agent':
              Api().post('devis/filtre/courtier/periode/' + store.state.user.societe, {
                du: this.du,
                au: this.au
              })
                  .then(resultat => {
                    this.listdevis = resultat.data.devis
                  });
              break;
  
            case 'assureur':
              Api().post('devis/filtre/assureur/periode/' + store.state.user.societe, {
                du: this.du,
                au: this.au
              })
                  .then(resultat => {
                    this.listdevis = resultat.data.devis
                  });
              break;
            case 'agent général':
              Api().post('devis/filtre/assureur/periode/' + store.state.user.assureur, {
                du: this.du,
                au: this.au
              })
                  .then(resultat => {
                    this.listdevis = resultat.data.devis
                  });
              break;
  
            default:
              break;
          }
        }
  
      },
      toggleDetails(item){
        this.detailsUser = item
        this.editUser = true
      },

      AddbrancheConfig() {
        Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
            .then((result) => {
              this.configUserDevisAssureur = []
            }).catch((err) => {
          console.log(err)
        });
      },
  
      pushConfig() {
        if(this.Selectedbranche && this.selectedAssureur)
        {
          this.configUserDevisAssureur.push({
            branche:this.Selectedbranche,
            assureurs:this.selectedAssureur,
            userid: this.selectedUser.id
          })
          this.Selectedbranche  = null
          this.selectedAssureur = []
        }
      },
  
      loadConfigBranchePopUp(item) {
        this.modalSociete = true
        this.selectedUser = item
        Api().post("/branchecourtier/get/all/", {userid:item.id})
            .then((response) => {
              this.configUserDevisAssureur = response.data.partenaires
            })
            .catch((err) => {});
      },
  
      setCollapse(){
        this.collapse = "id_"+Date.now()+"_collapse"
      },
  
      MenuDelete(item)
      {
        Api().delete("/menu/"+item._id)
            .then((result) => {
              this.getUserMenu(this.detailsUser._id)
            }).catch((err) => {
  
        });
      },
  
      pushMenu()
      {
        if(this.menu_name && this.userSubmenu.length >= 1)
        {
          this.UserFinalMenu.push({
            user : this.detailsUser.id,
            theme:this.menu_name,
            collapse:this.collapse,
            config:this.userSubmenu
          })
          this.menu_name   = null
          this.userSubmenu = []
          this.collapse    = null
        }else{
          this.$toasted.show("Remplissez les champs vides !", this.ToastTabError);
        }
      },
  
      create_user_menu()
      {
        if(this.UserFinalMenu.length >=1 )
        {
          Api().post("/menu/add", this.UserFinalMenu)
              .then((result) => {
                this.$toasted.show("Opération effectuée !", this.ToastTabSucess);
                this.UserFinalMenu = []
              }).catch((err) => {
            console.log(err)
          });
        }else{
          this.$toasted.show("Le menu ne doit pas être vide !", this.ToastTabError);
        }
      },
  
      merge(){
        Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
            .then((result) => {
              this.showuser = false
              if(result.data.result){
                alert('Opération réussie')
              }
            }).catch((err) => {
          console.log('=======err=========')
        });
      },
  
      detailuser(item) {
        this.getUserMenu(item._id)
        this.detailsUser = item
        this.showuser = true
      },
  
      ready: function () {
        var self = this;
  
        setInterval(function () {
          self.$data.ticker = Date.now();
        }, 1000);
      },
  
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
  
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
  
      getUserList:function(){
        switch (this.$store.state.user.role) {
          case 'Admin':
            Api().get('/user/frontoffice/')
                .then( resultat => {
                  this.listuser = resultat.data
                  this.totalRows = resultat.data.length
                });
            break;
  
  
          case 'assureur':
            Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
                .then( resultat => {
                  this.listuser = resultat.data
                  this.totalRows = resultat.data.length
                });
            break;
  
          default:
            break;
        }
      },
  
      UpdateUser(item) {
  
        let idU = item.id
        Api().post('user/' + idU, item)
            .then(response => {
              if (response.data) {
                this.showAlert()
              }
              this.showAlert()
            })
            .catch(err => {
  
            })
  
      },
  
      DateFormater: function (TheDate) {
        if (TheDate) {
          return dayjs(TheDate).format('DD-MM-YYYY')
        }
      },
  
      userDeleteConfirmation(item) {
  
        this.modalDelete = true
        this.detailsUser = item
  
      },
  
      DeletingUser(id) {
        Api().delete('user/delete/' + id)
            .then(resultat => {
              if (resultat.status == 200) {
                this.modalDelete = false
                this.getUserList()
              }
            })
            .catch(err => {})
      },
  
      getUserMenu(id){
        Api().post('/menu/user/menu', {userid:id})
            .then((result) => {
              this.usermenu = result.data.menus
            }).catch((err) => {
          console.log(err)
        });
      },
  
  
  
  
  
  
  
  
      calcDims() {
        const tableWrapper = document.getElementById("table-wrapper")
        if (tableWrapper) this.width = tableWrapper.clientWidth
  
        if (!this.isMobile && tableWrapper) {
          this.height = tableWrapper.clientHeight - 44
        }
  
        if (this.width < 480) {
          this.pagination.small = true
          this.pagination.layout = "prev, pager, next"
        } else if (this.width >= 480 && this.width < 700) {
          this.pagination.small = false
          this.pagination.layout = "prev, pager, next, ->, sizes"
        } else {
          this.pagination.small = false
          this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
        }
  
        this.ready = true
      },
      handleResize: _.throttle(function(e) {
        this.ready = false
        this.width = 0
        setTimeout(this.calcDims, 1000)
      }, 500),
      handleSelectionChange(val) {
        this.itemsChecked = val
      },
      init() {
        if (window.innerWidth <= 768) this.isMobile = true
      }
    },
    filters: {
      selected: function(value, sel) {
        if (!value) return ""
        if (!sel) return value
  
        value = value.toString()
        sel = sel.toString()
  
        const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
        if (startIndex !== -1) {
          const endLength = sel.length
          const matchingString = value.substr(startIndex, endLength)
          return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
        }
  
        return value
      }
    },
    created() {
      this.init()
      //this.getAssistance()
    },
    mounted() {
      if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
        this.getAssistance()
      } else {
        this.$router.go(this.$router.push('/login'))
      }
  
      //ie fix
      if (!window.Number.parseInt) window.Number.parseInt = parseInt
  
      this.calcDims()
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "../../../assets/scss/_variables";
  
  .page-table {
    &.overflow {
      overflow: auto;
    }
  
    .toolbar-box {
      &.hidden {
        visibility: hidden;
      }
    }
  
    .table-box {
      overflow: hidden;
  
      &.hidden {
        visibility: hidden;
      }
    }
  }
  </style>
  
  <style lang="scss">
  @import "../../../assets/scss/_variables";
  
  .page-table {
    .toolbar-box {
      margin-bottom: 10px;
  
      .card-shadow--medium {
        &.el-input,
        &.el-select {
          border-radius: 4px;
  
          input {
            border-color: transparent;
            background-color: lighten($background-color, 2%);
            color: $text-color;
  
            &:hover {
              border-color: #c0c4cc;
            }
            &:focus {
              border-color: $text-color-accent;
            }
          }
        }
  
        &.el-button {
          border-color: transparent;
        }
      }
    }
  
    .clickable {
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }
  
    .sel-string {
      .sel {
        background: transparentize($text-color, 0.8);
        border-radius: 5px;
        //text-transform: uppercase;
      }
    }
  }
  
  @media (max-width: 768px) {
    .page-table {
      .toolbar-box {
        display: block;
        overflow: hidden;
        font-size: 80%;
        padding-bottom: 10px;
  
        & > * {
          display: inline-block;
          min-width: 120px;
          height: 22px;
          //background: rgba(0, 0, 0, 0.04);
          margin-bottom: 16px;
        }
      }
    }
  }
  .el-table .warning-row {
    background: #FFB8C8;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  </style>
  