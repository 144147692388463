<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Nouveau frais de <span style="color:#ffa409">dossier</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Montant</label>
              <el-input type="number" placeholder="Montant" v-model="montant">
              </el-input>
            </div>
          </el-col>
           <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Partenaire</label>
              <el-select style="width: 100%" v-model="partenaireid" placeholder="Selectionner">
                <el-option v-for="assur in listeAssureurs" :key="assur.societe" :label="assur.societe" :value="assur.id">{{assur.societe}}</el-option>
              </el-select>
            </div>
          </el-col>
         <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Branche</label>
              <el-select style="width: 100%" v-model="branche" placeholder="Selectionner">
                <el-option v-for="br in listeBaranches" :key="br._id" :label="br.branche" :value="br._id">{{br.branche}}</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="CreateFraisDossier()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>

     <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Montant</label>
              <el-input type="number" placeholder="Montant" v-model="montant">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Partenaire</label>
              <el-select style="width: 100%" v-model="partenaireid" placeholder="Selectionner">
                <el-option v-for="assur in listeAssureurs" :key="assur.societe" :label="assur.societe" :value="assur.id">{{assur.societe}}</el-option>
              </el-select>
            </div>
          </el-col>
         <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Branche</label>
              <el-select style="width: 100%" v-model="branche" placeholder="Selectionner">
                <el-option v-for="br in listeBaranches" :key="br._id" :label="br.branche" :value="br._id">{{br.branche}}</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="CreateFraisDossier()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
        isMobile: false,
        dateBeforeToday: {
            disabledDate(time) {
            return time.getTime() > Date.now();
            }
        },
        listeAssureurs: null,
        listeBaranches: null,
        partenaireid :[],
        ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
                }, // Customized options for success toast
                ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'error'
                },// Customized options for error toast
        branche:null,        
        montant:null,        
        partenaireid:null,



      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: []
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
        this.getListPartenaire()
        this.getListBranche()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    window.addEventListener("resize", this.__resizeHanlder)
  },

  methods: {
     init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

    getListPartenaire(){ 
      if (this.$store.state.user.role=='Agent')
      {
        Api().get('/partenaire/courtier/'+this.$store.state.user.societe)
          .then(response => {
            this.listeAssureurs = response.data.partenaire[0].assureurs_partenaires
          }).catch(err=>{})
      } else if(this.$store.state.user.role=='agent général'){
        Api().get('/partenaire/'+this.$store.state.user.societe)
          .then(response => {this.listeAssureurs = response.data.partenaire})
      }else if(this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin'){
        Api().get('/partenaire/')
          .then(response => {
            // alert('ok')
            this.listeAssureurs = response.data.partenaires //.assureurs_partenaires
          })
      }else if (this.$store.state.user.role=='Assureur'){
        Api().get('/partenaire/'+this.$store.state.user.societe)
          .then(response => {this.listeAssureurs = response.data.partenaire})
      }

      console.log("Partenaires : "+this.listeAssureurs)
    },

          getListBranche(){
               Api().get('/branche')
                  .then(response => {
                    this.listeBaranches = response.data
                  }).catch(err=>{})
          },

          CreateFraisDossier(){
            if (this.partenaireid && this.montant && this.branche)
             {
        
                      const frais = {
                        montant:this.montant,
                        partenaireid:this.partenaireid,
                        brancheid:this.branche
                      }
                      
                      Api().post('/fraisdossier/create', frais)
                        .then(response=>{
                          if (response.data.ok==1) {
                            this.$message.success(response.data.message, this.AlertMessage)
                                this.montant=""
                                this.partenaireid=""
                                this.branche=""
                          }
                        }).catch(e=>{
                            this.$message.error(response.data.message, this.AlertMessage,e)
                        })
               
             }else{
                this.$message.error("Tous les champs sont obligatoires", this.AlertMessage)
             }
          },




    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),
   
   
    initGridData() {
      const year = new Date().getFullYear()
      const status_list = ["Complete", "Pending", "Returned", "Paid"]

      _.times(10, number => {
        let price = chance.floating({ min: 1, max: 100, fixed: 2 })
        let qnt = chance.integer({ min: 1, max: 5 })
        let amount = price * qnt

        this.gridData.push({
          customer: chance.name(),
          photo: "/static/images/shop/" + chance.integer({ min: 0, max: 19 }) + ".jpg",
          city: chance.city(),
          address: chance.address(),
          email: chance.email(),
          product: chance.sentence({ words: 3 }),
          price: _.replace(price.toFixed(2).toString(), ".", ","),
          qnt,
          amount: _.replace(amount.toFixed(2).toString(), ".", ","),
          status: status_list[chance.integer({ min: 0, max: 3 })],
          date: chance.date({ string: true, year: year }),
          id: number
        })
      })
    }
  },
   created() {
    this.init()
  },
  computed : {
    sortOptions () {
      return this.listuser
          .filter(f => f.sortable)
          .map(f => { return { text: f.label, value: f.key } })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
