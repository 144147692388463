<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des <span style="color:#ffa409">packs santé</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="mb-5">
      <el-button @click="toCreatePack()" type="primary" round>Créer nouveau pack</el-button>
    </div>

     <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher pack santé..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher pack santé..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />
    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" fixed prop="created">
         <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.created) }}</span>
          </template></el-table-column>
        <el-table-column label="Nom du pack" min-width="200" prop="nompack"></el-table-column>
        <el-table-column label="Type de pack" min-width="150" prop="groupe_ou_individuel"></el-table-column>
        <el-table-column label="Prix du pack" min-width="150" prop="prixpack">
               <template slot-scope ="scope">
            <span class="date">{{scope.row.prixpack.toLocaleString()}} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Périodicité du pack" min-width="200" prop="periode"></el-table-column>
        <el-table-column label="Créateur" min-width="150" prop="userid">
               <template slot-scope ="scope">
            <span class="date">{{scope.row.userid.prenom}} - {{scope.row.userid.nom}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="140">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailpackVue(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
            <el-button size="mini" type="primary" @click="editPackSabte(scope.row)" icon="el-icon-edit" circle title="Modifier"></el-button>
            <el-button size="mini" v-if="$store.state.user.role=='Admin' || $store.state.user.role=='SuperAdmin'" @click="packDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle title="Supprimer"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" prop="created">
         <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.created) }}</span>
          </template></el-table-column>
        <el-table-column label="Nom du pack" min-width="200" prop="nompack"></el-table-column>
        <el-table-column label="Type de pack" min-width="150" prop="groupe_ou_individuel"></el-table-column>
        <el-table-column label="Prix du pack" min-width="150" prop="prixpack">
               <template slot-scope ="scope">
            <span class="date">{{scope.row.prixpack.toLocaleString()}} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Périodicité du pack" min-width="200" prop="periode"></el-table-column>
        <el-table-column label="Créateur" min-width="150" prop="userid">
               <template slot-scope ="scope">
            <span class="date">{{scope.row.userid.prenom}} - {{scope.row.userid.nom}}</span>
          </template>
        </el-table-column>
         <el-table-column label="Actions" prop="action" min-width="140">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailpackVue(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
            <el-button size="mini" type="primary" @click="editPackSabte(scope.row)" icon="el-icon-edit" circle title="Modifier"></el-button>
            <el-button size="mini" v-if="$store.state.user.role=='Admin' || $store.state.user.role=='SuperAdmin'" @click="packDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle title="Supprimer"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détails pack" :visible.sync="showpack" v-if="showpack==true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailpack.created)}}</span></li>
                  <li>Nom du pack : <span class="to-right assuraf-orange">{{detailpack.nompack}}</span></li>
                  <li>Prix du pack : <span class="to-right assuraf-orange">{{detailpack.prixpack.toLocaleString()}}</span></li>
                  <li>Groupe / Individuel : <span class="to-right assuraf-orange">{{detailpack.groupe_ou_individuel.toUpperCase()}}</span></li>
                  <li>Nombre de personnes minimum : <span class="to-right assuraf-orange">{{detailpack.nb_personne_min}}</span></li>
                  <li>Nombre de personnes maximum : <span class="to-right assuraf-orange">{{detailpack.nb_personne_max}}</span></li>
                  <li>Périodicité du pack : <span class="to-right assuraf-orange">{{detailpack.periode}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailpack.partenaireid.logo" width="200" alt="logo assureur">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails pack" :visible.sync="showpack" v-if="showpack==true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailpack.created)}}</span></li>
                  <li>Nom du pack : <span class="to-right assuraf-orange">{{detailpack.nompack}}</span></li>
                  <li>Prix du pack : <span class="to-right assuraf-orange">{{detailpack.prixpack.toLocaleString()}}</span></li>
                  <li>Groupe / Individuel : <span class="to-right assuraf-orange">{{detailpack.groupe_ou_individuel.toUpperCase()}}</span></li>
                  <li>Nombre de personnes minimum : <span class="to-right assuraf-orange">{{detailpack.nb_personne_min}}</span></li>
                  <li>Nombre de personnes maximum : <span class="to-right assuraf-orange">{{detailpack.nb_personne_max}}</span></li>
                  <li>Périodicité du pack : <span class="to-right assuraf-orange">{{detailpack.periode}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailpack.partenaireid.logo" width="200" alt="logo assureur">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

   <el-dialog width="70%!important" title="Formulaire de modification du pack" :visible.sync="editPack" v-if="editPack==true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="8">
              <div>
                <label>Nom</label>
                <el-input v-model="detailpack.nompack" placeholder="Nom du pack" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Prix</label>
                <el-input type="number" :min="0" v-model="detailpack.prixpack" placeholder="Prix du pack..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Nombre de personnes minimum</label>
                <el-input type="number" :min="1" v-model="detailpack.nb_personne_min" placeholder="Nombre de personnes" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-top:15px">
            <el-col :span="8">
              <div>
                <label>Période</label>
                <el-select style="width: 100%" v-model="detailpack.periode" placeholder="Selectionner">
                <el-option value="unique" label="Unique">Unique</el-option>
                <el-option value="annuelle" label="Annuelle">Annuelle</el-option>
                <el-option value="mensuelle" label="Mensuelle">Mensuelle</el-option>
              </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Groupe / Individuel</label>
                <el-select style="width: 100%" v-model="detailpack.groupe_ou_individuel" placeholder="Selectionner">
                <el-option value="groupe" label="Groupe">Groupe</el-option>
                <el-option value="individuel" label="Indivuel">Indivuel</el-option>
              </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Nombre de personnes maximum</label>
                <el-input type="number" :min="1" v-model="detailpack.nb_personne_max" placeholder="Nombre de personnes" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-top:15px">
            <el-col :span="8">
              <div>
                <label>Entreprise / Individuel</label>
                <el-select style="width: 100%" v-model="detailpack.entreprise_individuel" placeholder="Selectionner">
                <el-option value="entreprise" label="Entreprise">Entreprise</el-option>
                <el-option value="individuel" label="Individuel">Individuel</el-option>
              </el-select>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="UpdatePackSante()">Modifier</el-button>
          <el-button type="danger" round @click="editPack=false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="90%!important" title="Formulaire de modification du pack" :visible.sync="editPack" v-if="editPack==true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="24">
              <div>
                <label>Nom</label>
                <el-input v-model="detailpack.nompack" placeholder="Nom du pack" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Prix</label>
                <el-input type="number" v-model="detailpack.prixpack" placeholder="Prix du pack..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Nombre de personnes minimum</label>
                <el-input type="number" v-model="detailpack.nb_personne_min" placeholder="Nombre de personnes" class="input-with-select"></el-input>
              </div>
            </el-col>
             <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Période</label>
                <el-select style="width: 100%" v-model="detailpack.periode" placeholder="Selectionner">
                <el-option value="unique" label="Unique">Unique</el-option>
                <el-option value="annuelle" label="Annuelle">Annuelle</el-option>
                <el-option value="mensuelle" label="Mensuelle">Mensuelle</el-option>
              </el-select>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Groupe / Individuel</label>
                <el-select style="width: 100%" v-model="detailpack.groupe_ou_individuel" placeholder="Selectionner">
                <el-option value="groupe" label="Groupe">Groupe</el-option>
                <el-option value="individuel" label="Individuel">Indivuel</el-option>
              </el-select>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Nombre de personnes maximum</label>
                <el-input type="number" v-model="detailpack.nb_personne_max" placeholder="Nombre de personnes" class="input-with-select"></el-input>
              </div>
            </el-col>
             <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Entreprise / Individuel</label>
                <el-select style="width: 100%" v-model="detailpack.entreprise_individuel" placeholder="Selectionner">
                <el-option value="entreprise" label="Entreprise">Entreprise</el-option>
                <el-option value="individuel" label="Individuel">Individuel</el-option>
              </el-select>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="UpdatePackSante()">Modifier</el-button>
          <el-button type="danger" round @click="editPack=false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="Suppression pack" width="50%" :visible.sync="modalDelete" v-if="modalDelete==true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer ce pack?</span>
          </div>
          <div  >
            <ul style="list-style:none">
              <li>Nom : <span class="to-right assuraf-orange">{{detailpack.nompack}}</span></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="danger" round @click="Deletingpack(detailpack._id)">Oui</el-button>
        <el-button type="primary" round @click="modalDelete = false">Non</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
        editPack : false,
        entreprise_ou_individuel:null,
        prixpack:null,
        nompack:null,
        groupe_ou_individuel:null,
        entreprise_individuel:null,
        nb_personne_min:null,
        periode :null,
        groupe_individuel:null,
        nb_personne_max:null,
        listeAssureurs: null,
        partenaireid :[],
        listpack: [],
        detailpack: [],
        showpack: false,
        deleteConfirmation: false,
        modalDelete: false,
        filter: null,
        totalRows: null,
        ToastTabSucess: {
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 2000,
          fitToScreen: true,
          type: 'success',
          className: 'custom-success-class'
        }, // Customized options for success toast
        ToastTabError: {
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 2000,
          fitToScreen: true,
          type: 'error'
        },// Customized options for error toast
        perPage: 3,
        pageOptions: [5, 10, 15],
        fields: [
          {key: 'created', label: 'Date création'},
          {key: 'nompack', label: 'Nom du pack'},
          {key: 'groupe_ou_individuel', label: 'Type de pack'},
          {key: 'prixpack', label: 'Prix du pack'},
          {key: 'periode', label: 'Périodicité du pack'}, 
          {key: 'userid', label: 'Createur'},
          {key: 'action', label: 'Action'},

        ],
      userflette:[],
      fletteRows:null,



      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      listeAssureurs: null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      modalInfo: {
        title: '',
        content: ''
      },
      MenuHeader:[
        {key: "theme",label: "Libéllé"},
        {key: "collapse",label: "Collapse"},
        {key: "sousmenu",label: "Sous menu"},
        {key: "action", label:"action"}
      ],
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list:  this.listpack,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.listpack.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },

    listFilteredFlotte() {
      return this.userflette.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    listSorteredFlotte() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFilteredFlotte.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPageFlotte() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      return this.listSorteredFlotte.slice(from, to)
      return this.listFilteredFlotte.slice(from, to)
    },
    totalFlotte() {
      return this.listFilteredFlotte.length
    },

    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    exportToExcel(){
          //API de genereation donne en excel
        },
     AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },
    editPackSabte(item){
      this.editPack = true
      this.detailpack = item
    },

    toCreatePack (){
      this.$router.go(this.$router.push('/packsante/create'))
    },

    UpdatePartenaireLogo: function (e, item) {
      var files = e.target.files || e.dataTransfer.files;
      this.file = this.$refs.file.files[0];

      let formData = new FormData();
      formData.append("logo", this.file);
      formData.append("partenaireId", this.idP);
      Api().post('partenaire/updatelogo/', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
          .then(response => {
            this.ServerRep = response.data;
          })
    },

    UpdatePackSante(){
      let pack = this.detailpack
            if (pack.nompack && pack.prixpack && pack.nb_personne_min && pack.periode)
             {
                if (pack.groupe_ou_individuel && pack.nb_personne_max && pack.groupe_ou_individuel) {
                    let pack = this.detailpack
                      Api().post('/packsante/update', pack)
                        .then(response=>{
                          if (response.data.ok==1) {
                             this.editPack = false
                             this.getpack()
                             this.$message.success(response.data.message, this.AlertMessage)
                          }else{
                             this.getpack()
                             this.editPack = false
                             this.$message.success("il n'y a pas eu de modification !", this.AlertMessage)
                          }
                        }).catch(e=>{
                          this.$toasted.show(response.data.message, this.ToastTabSucess,e)
                        })
                }else{
                    this.$message.error('Touts les champs sont obligatoires !', this.AlertMessage)
                }
             }else{
                this.$message.error('Touts les champs sont obligatoires !', this.AlertMessage)
             }
    },

    getpack(){
      if(this.$store.state.user.role=='Assureur')
          {
            Api().get('/packsante/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listpack = resultat.data.packs
                this.totalRows = resultat.data.count 
                });
          }else if (this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin'){
            Api().get('/packsante/')
              .then(resultat => {
                this.listpack = resultat.data.packs
                this.totalRows = resultat.data.count
              });
          }

        },

    packDeleteConfirmation(item){
    this.modalDelete = true
    this.detailpack = item
    },
    
    Deletingpack(id){
      Api().delete('packsante/delete/'+id)
      .then(resultat => { 
          if (resultat.data.rep.n==1) 
          {
              this.modalDelete = false
              this.getpack()
          }
      })
    },

     detailpackVue(item) {

        this.detailpack = item
        this.showpack = true
      },






    getFlette(userid)
    {
      this.userflette = []
      Api().post('/flette/listing', {userid:userid})
          .then( resultat => {
            this.userflette = resultat.data.cars
            this.fletteRows = resultat.data.cars.length
          });
    },

    toggleDetails(item){
      this.detailsUser = item
      this.editUser = true
    },







    DeleteBrancheCourtier(item) {
      Api().post("/branchecourtier/delete", {id:item._id})
          .then((result) => {
            Api().post("/branchecourtier/get/all/", {userid:item.userid})
                .then((response) => {
                  this.configUserDevisAssureur = response.data.partenaires
                })
                .catch((err) => {});
          }).catch((err) => {

      });
    },

    AddbrancheConfig() {
      Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
          .then((result) => {
            this.configUserDevisAssureur = []
          }).catch((err) => {
        console.log(err)
      });
    },

    pushConfig() {
      if(this.Selectedbranche && this.selectedAssureur)
      {
        this.configUserDevisAssureur.push({
          branche:this.Selectedbranche,
          assureurs:this.selectedAssureur,
          userid: this.selectedUser.id
        })
        this.Selectedbranche  = null
        this.selectedAssureur = []
      }
    },

    loadConfigBranchePopUp(item) {
      this.modalSociete = true
      this.selectedUser = item
      Api().post("/branchecourtier/get/all/", {userid:item.id})
          .then((response) => {
            this.configUserDevisAssureur = response.data.partenaires
          })
          .catch((err) => {});
    },

    setCollapse(){
      this.collapse = "id_"+Date.now()+"_collapse"
    },

    MenuDelete(item)
    {
      Api().delete("/menu/"+item._id)
          .then((result) => {
            this.getUserMenu(this.detailsUser._id)
          }).catch((err) => {

      });
    },

    pushMenu()
    {
      if(this.menu_name && this.userSubmenu.length >= 1)
      {
        this.UserFinalMenu.push({
          user : this.detailsUser.id,
          theme:this.menu_name,
          collapse:this.collapse,
          config:this.userSubmenu
        })
        this.menu_name   = null
        this.userSubmenu = []
        this.collapse    = null
      }else{
        this.$toasted.show("Remplissez les champs vides !", this.ToastTabError);
      }
    },

    create_user_menu()
    {
      if(this.UserFinalMenu.length >=1 )
      {
        Api().post("/menu/add", this.UserFinalMenu)
            .then((result) => {
              this.$toasted.show("Opération effectuée !", this.ToastTabSucess);
              console.log(result.data)
              this.UserFinalMenu = []
            }).catch((err) => {
          console.log(err)
        });
      }else{
        this.$toasted.show("Le menu ne doit pas être vide !", this.ToastTabError);
      }
    },

    merge(){
      Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
          .then((result) => {
            this.showuser = false
            if(result.data.result){
              alert('Opération réussie')
            }
          }).catch((err) => {
        console.log('=======err=========')
      });
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList:function(){
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
          .then(response => {
            if (response.data) {
              this.showAlert()
            }
            this.showAlert()
          })
          .catch(err => {

          })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },
    modalDeleteCarShow(item){
      this.carToDelete    = item
      this.modalDeleteCar = true
    },

    confirmuserCarDeletion()
    {
      Api().post('/flette/delete', {id:this.carToDelete._id})
          .then((result) => {
            this.modalDeleteCar = false
          }).catch((err) => {
        console.log("ERROR")
      });

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
          .then(resultat => {
            if (resultat.status == 200) {
              this.modalDelete = false
              this.getUserList()
            }
          })
          .catch(err => {})
    },

    getUserMenu(id){
      Api().post('/menu/user/menu', {userid:id})
          .then((result) => {
            this.usermenu = result.data.menus
            console.log(result.data.menus)
          }).catch((err) => {
        console.log(err)
      });
    },








    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
    this.getpack()

  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
    this.getpack()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
